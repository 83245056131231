import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  addRestaurantsData,
  resetMap,
  setActiveMap,
  setMapBbox,
  setMapCenter,
  setMapZoom,
  setRestaurantsStatus,
  setSelectedData,
  setSelectedStatus,
} from '@widgets/Restaurant/Map/model/slice'
import { fetchRests, fetchRestGallery } from '@widgets/Restaurant/Map/model/thunks'
import { globalStore } from '@app/model/store'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { API } from '@shared/api'
import { TypeMapContextState } from '@widgets/Restaurant/Map/MapContext'
import { TypeMapStateDetail } from '@widgets/Restaurant/Map/model/types'
import { LngLatBoundsClass } from '@2gis/mapgl/types/objects/lngLatBounds'

/**
 * Работа store карты происходит по следующему циклу (это касается как первого отображения, так дозагрузки данных):
 * 1. Получение, создание/расширение массива и отображение маркеров (markers)
 * 2. Заполнение restaurant.list пустыми элементами до соответствующей с markers.list длины массива
 * 3. Замещение пустых данных в restaurant.list полными по мере необходимости (клики на пины, переход на ресторан, показ листинга)
 */

export type TypePropsOpenMapAC = {
  selectedSlug?: string
  preloadRests?: TypeMappingRestOutput[]
  applyFilters?: any
}

export const openMapAC = (data?: TypePropsOpenMapAC) => async (dispatch: Dispatch<AnyAction>) => {
  const urlParams = new URLSearchParams(window.location.search)
  const getQueryMap = urlParams.get('map')

  if (!getQueryMap) {
    const baseUrl = window.location.origin + window.location.pathname
    const newUrl = baseUrl + '?map=true'
    history.pushState(null, null, newUrl)
  }

  if (data?.preloadRests) {
    // @ts-ignore
    dispatch(setPreloadRestData(data.preloadRests))
  }

  if (data?.selectedSlug) {
    // @ts-ignore
    // TODO: доработать
    // dispatch(setRestSelectedAC({ slug: data.selectedSlug, eventFrom: 'init' }))
  }

  dispatch(setActiveMap(true))

  // if (!data?.selectedSlug && !data?.preloadRests) {
  //   // @ts-ignore
  //   const response = await dispatch(fetchMarkers())
  //   // @ts-ignore
  //   if (isMobile && !!response.payload?.length)
  //     // @ts-ignore
  //     dispatch(setRestSelectedAC({ slug: response.payload[0].slug, eventFrom: 'init' }))
  // }
}

// const cycleFetchRests = (data: { bbox: LngLatBoundsClass; page: number }) => {}

export const updateMapCenter =
  ({ bbox, center, zoom }: TypeMapStateDetail) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { isMobile } = globalStore.getState().global.client.responsive
    const { restaurants, selected } = globalStore.getState().features.map

    dispatch(setMapCenter(center))
    dispatch(setMapBbox(bbox))
    dispatch(setMapZoom(zoom))

    if (restaurants.status === 'loading') return
    // Если зум очень далекий, то ничего не делаем
    if (zoom < 10) return

    // @ts-ignore
    const response = await dispatch(fetchRests())

    // @ts-ignore
    if (isMobile && response.payload.results?.length && !selected.data) {
      dispatch(
        // @ts-ignore
        setRestSelectedAC({
          // @ts-ignore
          restData: response.payload.results[0],
          eventFrom: 'init',
        }),
      )
    }
  }

export const setPreloadRestData = (list: TypeMappingRestOutput[]) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(addRestaurantsData(list))
  dispatch(setRestaurantsStatus('success'))
}

export const closeRestCardDesktopAC = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSelectedData(null))
  dispatch(setSelectedStatus('empty'))

  const baseUrl = window.location.origin + window.location.pathname
  const newUrl = baseUrl + '?map=true'
  history.pushState(null, null, newUrl)
}

export const closeMapAC = () => (dispatch: Dispatch<AnyAction>) => {
  const baseUrl = window.location.origin + window.location.pathname
  history.pushState(null, null, baseUrl)

  dispatch(setActiveMap(false))
  dispatch(resetMap())
}

type TypeSetRestSelectedAC = {
  restData?: TypeMappingRestOutput
  restSlug?: string

  eventFrom: 'slider' | 'pin' | 'card' | 'init'
  mapInstance?: TypeMapContextState
}
export const setRestSelectedAC =
  ({ restData, restSlug, eventFrom, mapInstance }: TypeSetRestSelectedAC) =>
  async (dispatch: Dispatch<AnyAction>) => {
    if (eventFrom !== 'init' && !mapInstance) return

    const { restaurants, selected, galleries } = globalStore.getState().features.map
    const { isMobile } = globalStore.getState().global.client.responsive

    // Если данных на карте нет, то подразумевается, что карта была открыта с выбранным рестораном (в openMapAC data.selectedSlug)
    // В таком случае будет отображен только маркер выбранного ресторана
    if (restaurants.status === 'empty' || restSlug) {
      API.restaurant_read({ path: restSlug }).then((rest) => {
        dispatch(addRestaurantsData([rest]))
        dispatch(setRestaurantsStatus('success'))
        dispatch(setSelectedData(rest))
        dispatch(setSelectedStatus('success'))
      })
      return
    }

    if (selected.data && restData && restData.id === selected.data.id) return

    // Обновляем URL
    const { origin, pathname } = window.location
    const linkHrefForCard = `${origin + pathname}?map=true&restaurant=${restData ? restData.url.slug : restSlug}`
    history.pushState(null, null, linkHrefForCard)

    // передвигаем карту только если был клик по карточке или при перелистывании слайдера
    if (mapInstance && ['slider', 'card'].includes(eventFrom)) {
      // если десктоп, то смещаем центр вправо, так как левую часть занимает карточка ресторана (примерно 500px шириной)
      !isMobile && mapInstance.mapCreated.setPadding({ left: 500 })

      mapInstance.mapCreated.setZoom(13)
      mapInstance.mapCreated.setCenter(restData.address.coordinates)
    }

    dispatch(setSelectedData(restData))
    dispatch(setSelectedStatus('success'))

    // Для десктопа проверяем загружена ли галерея. Если нет, то подгружаем
    if (!isMobile) {
      const isLoadedGallery = restData.id in galleries.data

      // @ts-ignore
      !isLoadedGallery && dispatch(fetchRestGallery(restData.id))
    }
  }
