import React, { FC } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { SelectionCardCompilation } from '@widgets/Selection/SelectionCardCompilation'
import { NewsCardCompilation } from '@widgets/News/NewsCardCompilation'
import s from './CompilationGrid.module.sass'
import { CompilationGridSkeleton } from '@widgets/News/CompilationGrid/index'

interface Props {
  counts: string // example - '6 4 4' (desktop-large, desktop-medium, tablet)
  type: 'selections' | 'news'
  list: TypeMappingNewsOutput[] | TypeMappingSelectionOutput[]
  className?: string
  analytic: {
    section_name: string
    analytic_indexes?: number[]
  }
  skeleton?: boolean
}

const components = {
  selections: SelectionCardCompilation,
  news: NewsCardCompilation,
}

// Component for all breakpoints except for mobile
const CompilationGrid: FC<Props> = ({ list, type, counts = '6 4 4', analytic, className = '', skeleton = false }) => {
  const [countsCurrentBreakpoint] = useCurrentSize(counts + ' -')
  const { isMobile } = useClientResponsive()

  const getSizeCard = (index, countsNumber, listLength) => {
    let schema = null

    switch (countsNumber) {
      case '6':
        if (listLength <= 2) schema = ['D2x1', 'D2x1']
        else if (listLength === 3) schema = ['D1x1', 'D1x1', 'D2x1']
        else if (listLength === 4) schema = ['D1x1', 'D1x1', 'D1x1', 'D1x1']
        else if (listLength === 5) schema = ['D1x1', 'D1x1', 'D2x1', 'D2x1', 'D2x1']
        else schema = ['D1x1', 'D1x1', 'D2x1', 'D2x1', 'D1x1', 'D1x1']
        break
      case '4':
        if (listLength === 1) schema = ['D2x1']
        else if (listLength === 2) schema = ['D1x1', 'D2x1']
        else if (listLength === 3) schema = ['D1x1', 'D1x1', 'D1x1']
        else schema = ['D1x1', 'D2x1', 'D2x1', 'D1x1']
        break
    }

    return schema[index]
  }
  if (!isMobile && skeleton) {
    return <CompilationGridSkeleton counts={counts} className={className} />
  } else if (!isMobile && !skeleton) {
    return (
      <ErrorBoundary>
        <div className={`${s.grid} ${className}`} data-mode={countsCurrentBreakpoint} data-elems-length={list.length}>
          {list.map((item, index) => {
            const indexLessThanMaximum = index <= Number(countsCurrentBreakpoint) - 1
            const CardComponent = components[type]

            if (indexLessThanMaximum) {
              const size = getSizeCard(index, String(countsCurrentBreakpoint), list.length)
              const isLongSize = size === 'D2x1'
              return (
                <CardComponent
                  key={item.id}
                  data={item}
                  // @ts-ignore
                  style={isLongSize ? { gridColumn: 'span 2', minWidth: 'initial' } : {}}
                  sizes={size}
                  className={s.item}
                  analytic={{
                    section_name: analytic.section_name,
                    section_index: analytic?.analytic_indexes ? analytic?.analytic_indexes[index] : index,
                  }}
                />
              )
            }
          })}
        </div>
      </ErrorBoundary>
    )
  }
}

export default CompilationGrid
