import React, { FC, useEffect } from 'react'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { openMapAC, setRestSelectedAC } from '@widgets/Restaurant/Map/model/actionCreator'
import MapContent from '@widgets/Restaurant/Map/MapContent'
import { useRouter } from 'next/router'
import { load } from '@2gis/mapgl'
import { resetMap } from '@widgets/Restaurant/Map/model/slice'

const Map: FC = () => {
  const [mapInstance, setMapInstance] = React.useState({
    mapglAPI: null,
    mapCreated: null,
  })
  const storeGlobal = useAppSelector((store) => store.global)
  const storeMap = useAppSelector((store) => store.features.map)
  const dispatch = useAppDispatch()
  const router = useRouter()

  useEffect(() => {
    const hasPreopenedUrlPath = window.location.search.includes('?map')
    if (hasPreopenedUrlPath && !storeMap.active)
      dispatch(openMapAC({ selectedSlug: (router.query.restaurant as string) || null }))
    else if (!hasPreopenedUrlPath && storeMap.active) {
      dispatch(resetMap())
      setMapInstance({
        mapglAPI: null,
        mapCreated: null,
      })
    }
  }, [router])

  return (
    storeMap.active && (
      <MapContext.Provider value={[mapInstance, setMapInstance]}>
        <MapContent />
      </MapContext.Provider>
    )
  )
}

export default Map
