import React, { FC, useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import s from './MapRestCard.module.sass'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { RestaurantSchedule } from '@widgets/Restaurant/RestaurantSchedule'
import { numWord } from '@widgets/Restaurant/Booking/lib/helper'
import { openBooking } from '@widgets/Restaurant/Booking'

import { Favorite } from '@features/Favorite'

import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { scrollToElem } from '@shared/lib/helpers/scrollToElem'
import { PriceRange } from '@shared/ui/Feedback/PriceRange'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import { Rating } from '@shared/ui/Feedback/Rating'
import { Button } from '@shared/ui/Actions/Button'
import { Metro } from '@shared/ui/Feedback/Metro'
import { Text } from '@shared/ui/Typography/Text'
import { EGG } from '@shared/api/analytics'

import { MapButtonActionForAside } from '@widgets/Restaurant/Map/ui/MapButtonActionForAside'
import { closeRestCardDesktopAC } from '@widgets/Restaurant/Map/model/actionCreator'
import { MapRestCardSkeleton } from '@widgets/Restaurant/Map/ui/MapRestCard/index'
import { TagsByRestaurant } from '@widgets/Restaurant/TagsByRestaurant'
import { MapRestMenu } from '../MapRestMenu'
import { MapRestCardCover } from '@widgets/Restaurant/Map/ui/MapRestCard/ui/MapRestCardCover'
import IconClose from '@icons/map/close-rest-card.svg'
import IconChevron from '@icons/icon-chevron-big.svg'
import IconLike from '@icons/icon-like.svg'

const LazyReviews = dynamic(
  () => import('@widgets/Restaurant/Map/ui/MapRestCard/ui/MapRestReviews').then((module) => module.MapRestReviews),
  {
    // loading: () => <RestaurantReviewsSkeleton />,
    ssr: false,
  },
)

const getAddressWithCity = (cityList, currentCityId, addressRest): string => {
  const cityName = cityList.find((city) => currentCityId === city.id)?.name
  return cityName ? `${cityName}, ${addressRest}` : addressRest
}

const MapRestCardContainer: FC = () => {
  const { restaurants, selected } = useAppSelector((store) => store.features.map)
  const restIsLoaded = !!selected.data && selected.status === 'success'

  return restIsLoaded ? <MapRestCard /> : <MapRestCardSkeleton />
}

const MapRestCard: FC = () => {
  const mapStore = useAppSelector((store) => store.features.map)
  const restData = mapStore.selected.data

  const globalStore = useAppSelector((store) => store.global)
  const dispatch = useAppDispatch()
  const {
    id,
    type,
    status,
    name,
    feedback,
    schedule,
    contacts,
    price,
    address,
    tags,
    menu,
    isShowUI,
    discounts,
    booking,
    images,
  } = restData
  const router = useRouter()

  const {
    img: isShowImg,
    likeButton: isShowLikeButton,
    reviewsButton: isShowReviewsButton,
    actionButtons: isShowActionButtons,
    rating: isShowRating,
    chevron: isShowChevron,
    price: isShowPrice,
    booking: isShowBooking,
  } = isShowUI

  const getMetaStateString = (value) => {
    if (typeof value === 'boolean') return value ? 'Есть' : 'Нет'
    else return value ? value : 'Нет'
  }

  const handleClose = () => {
    dispatch(closeRestCardDesktopAC())
  }

  // в приоритете перейти на листинг типа кухни,
  // если его нет то на тип ресторана,
  const getSimilarRestsLink = useMemo((): string | null => {
    let link = null
    const kitchenType = tags.searchable.find((itemTag) => itemTag.name.includes('кухня'))
    const restaurantType = tags.searchable.find((itemTag) => itemTag.name.includes(type))
    if (kitchenType) {
      link = kitchenType.url
    } else if (restaurantType) {
      link = restaurantType.url
    } else {
      link = tags?.searchable[0]?.url || null
    }

    if (link) {
      const url = link.split('/')
      url.splice(1, 1, globalStore.currentCity.slug)
      return url.join('/')
    } else {
      return null
    }
  }, [])

  const handleBooking = () => {
    if (booking.afisha.active) {
      dispatch(openBooking(restData as TypeMappingRestOutput))
    } else if (booking.tomesto.active) {
      window.open(booking.tomesto.url, '_blank')
    }

    EGG.entity.restaurant.click_booking(restData as TypeMappingRestOutput, { section_name: 'page' })
  }

  const isRestOpened = status.open || (!status.open && status.temporarily)
  const fullAddress = getAddressWithCity(globalStore.cityList, address.city.id, address.address)
  const countReviews = feedback.reviews.count
  const countReviewsText = `${countReviews} ${numWord(countReviews, ['отзыв', 'отзыва', 'отзывов'])}`

  return (
    <div className={s.card}>
      <MapButtonActionForAside onClick={handleClose} icon={IconClose} className={s['button-close']} />
      {isShowUI.chevron && <IconChevron className={s.chevron} />}
      <MapRestCardCover />
      <div className={s.gradient}></div>
      <div className={s['scroll-wrapper']}>
        <div className={s['scroll-content']}>
          <div className={s.header}>
            <div className={s['header-top']}>
              <div className={s['rest-additional-info-wrap']}>
                {type && (
                  <Text tag='span' className={s['rest-tag']} uppercase sizes='S'>
                    {type}
                  </Text>
                )}

                {isShowPrice && (
                  <>
                    <span className={s['price-dot']}></span>

                    <PriceRange
                      priceRange={price.level}
                      colorMode={'black'}
                      link={price.tag.url}
                      onClick={() => router.push(price.tag.url, price.tag.url, { scroll: false })}
                    />
                  </>
                )}
              </div>
              {isShowUI.rating && (
                <div className={s['rating-wrapper']}>
                  {countReviews > 0 && (
                    <div className={s['rating-right']}>
                      <Text tag='span' sizes='S' onClick={() => scrollToElem('reviews')} className={s['rating-count']}>
                        {countReviewsText}
                      </Text>
                    </div>
                  )}
                  {feedback.rating >= 4 && <Rating rating={feedback.rating} sizes={'S'} mode={'stroke'} />}
                </div>
              )}
            </div>
            <Title sizes={'h2'}>{name}</Title>

            {status.open ? (
              <RestaurantSchedule schedule={schedule} classNameButton={s['schedule-button']} />
            ) : (
              <>
                <Text
                  sizes={'M'}
                  className={s['rest-status']}
                  data-is-img={isShowUI.img}
                  data-opening-soon={status.openingSoon}>
                  {status.name}
                </Text>
                {getSimilarRestsLink && (
                  <Button
                    sizes='XL'
                    mode={'black'}
                    className={s['rest-booking-button']}
                    onClick={() => router.push(getSimilarRestsLink, getSimilarRestsLink, { scroll: false })}>
                    Найти похожее
                  </Button>
                )}
              </>
            )}

            {isShowUI.booking && (
              <div className={s['main-buttons-wrapper']}>
                <Button sizes='XL' mode={'black'} className={s['rest-booking-button']} onClick={handleBooking}>
                  Забронировать столик
                </Button>
                {discounts.remarked && (
                  <PromoBadgeCTA sizes={'XL'} className={s['promo-badge']}>
                    -{discounts.remarked}₽
                  </PromoBadgeCTA>
                )}
                {isShowUI.actionButtons && isShowUI.likeButton && (
                  <Favorite
                    category={'restaurant'}
                    id={id}
                    analytic={{
                      data: restData as TypeMappingRestOutput,
                      other: { section_name: 'page' },
                    }}>
                    <IconButton icon={IconLike} sizes='XL M' mode='white-border' />
                  </Favorite>
                )}
              </div>
            )}
          </div>
          <div className={s.meta}>
            <div className={s.table}>
              {/* Адрес */}
              <div className={`${s['table-cell']} ${s['table-cell--long']}`}>
                <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
                  Адрес
                </Text>
                <div className={s['cell-data']}>
                  <Text tag={'span'} sizes={'M S'}>
                    {fullAddress}
                  </Text>
                  <ul className={s['metro-list']}>
                    {address.metro &&
                      address.metro.map((metroItem) => (
                        <Metro
                          key={metroItem.id}
                          name={metroItem.name}
                          link={metroItem.url}
                          colors={metroItem.color}
                          id={s['metro-item']}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              {/* Телефон */}
              <div className={s['table-cell']}>
                <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
                  Телефон
                </Text>
                <div className={s['cell-data']}>
                  {contacts.phone.list.length > 0
                    ? contacts.phone.list.map((phoneItem, i) => (
                        <Text tag={'span'} sizes={'M S'} key={i}>
                          {phoneItem ? (
                            <a className={s['cell-data-link']} href={`tel:${phoneItem}`}>
                              {phoneItem}
                            </a>
                          ) : (
                            'Нет'
                          )}
                        </Text>
                      ))
                    : 'Нет'}
                </div>
              </div>

              {/* Средний чек */}
              <div className={s['table-cell']}>
                <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
                  Средний чек
                </Text>
                <div className={s['cell-data']}>
                  <Text tag={'span'} sizes={'M S'}>
                    {getMetaStateString(price.name.short)}
                  </Text>
                </div>
              </div>

              {/* Сайт */}
              <div className={s['table-cell']}>
                <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
                  Сайт
                </Text>
                <div className={s['cell-data']}>
                  <Text tag={'span'} sizes={'M S'}>
                    {contacts.site ? (
                      <a className={s['cell-data-link']} href={contacts.site} rel='nofollow' target='_blank'>
                        {contacts.site}
                      </a>
                    ) : (
                      'Нет'
                    )}
                  </Text>
                </div>
              </div>
            </div>

            <TagsByRestaurant tags={tags.searchable} restId={id} id={s.tags} className={s.tags} />
          </div>

          {menu && <MapRestMenu data={menu} id={s.menu} />}

          {isRestOpened && <LazyReviews restaurant={{ name, id }} />}
        </div>
      </div>
    </div>
  )
}

export default MapRestCardContainer
