import React, { FC } from 'react'
import s from './MapRestCarouselMobileNotFound.module.sass'
import { Text } from '@shared/ui/Typography/Text'

const MapRestCarouselMobileNotFound: FC = () => {
  return (
    <div id={'rest-card-map-mobile'} className={s.wrapper}>
      <div className={s.card}>
        <Text sizes={'S'}>Рестораны не найдены</Text>
      </div>
    </div>
  )
}

export default MapRestCarouselMobileNotFound
