import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingFavoritesGQLOutput } from '@shared/api/middleware/mappingAPI/gql_favorites/type'

export const mappingFavoritesGQL = (object: any): TypeMappingFavoritesGQLOutput => {
  const result = {
    restaurants: {},
    selections: {},
    concerts: {},
    news: {},
  }
  try {
    const favorites = object.data.user.activity.favorites.items

    favorites.forEach((item) => {
      const { id: idSubject, name: nameSubject } = item.subject

      const lowerId = idSubject.toLowerCase()

      const isRest = lowerId.includes('restaurant')
      const isNews = lowerId.includes('article')
      const isSelection = lowerId.includes('selection')

      const typeMap = {
        concert: 'concerts',
        exhibition: 'exhibitions',
        movie: 'movies',
        performance: 'performances',
        sport: 'sports',
      }

      if (isRest) result.restaurants[parseInt(idSubject.match(/\d+/))] = nameSubject || true
      if (isNews) result.news[parseInt(idSubject.match(/\d+/))] = nameSubject || true
      if (isSelection) result.selections[parseInt(idSubject.match(/\d+/))] = nameSubject || true

      Object.keys(typeMap).forEach((type) => {
        if (lowerId.includes(type)) {
          result[typeMap[type]][parseInt(idSubject.match(/\d+/))] = nameSubject || true
        }
      })
    })

    return undefinedObjectToNull(result)
  } catch (error) {
    console.error(error)
    return result
  }
}
