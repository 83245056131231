import React, { FC } from 'react'
import s from './MapRestMenu.module.sass'

import { TypeMenuItem } from '@widgets/Restaurant/RestMenuItem/types/menu'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { RestMenuItem } from '@widgets/Restaurant/RestMenuItem'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { Title } from '@shared/ui/Typography/Title'

interface Props {
  data: TypeMenuItem[]
  className?: string
}

const MapRestMenu: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  data,
  className = '',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <Carousel analytic={{ section_name: 'меню' }}>
        <div className={s.section}>
          <div className={s.header}>
            <SectionTitle className={s.title} titleComponent={<Title sizes={'h3'}>Меню</Title>} skeleton={false} />
            <Carousel.Controls />
          </div>
          <Carousel.Slider countSlidesOnContainer={2} className={s.slider} spacingDesktop={16}>
            {data.map((el, index) => {
              return <RestMenuItem sizes={'245x76'} key={el.id} {...el} analytic={{ section_index: index }} />
            })}
          </Carousel.Slider>
        </div>
      </Carousel>
    </ErrorBoundary>
  )
}

export default MapRestMenu
