import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { globalStore } from '@app/model/store'

// TODO: Вынести глобально
class ControllerSignal {
  // ссылки на new AbortController() текущего запроса для возможности прерывания.
  signalStack = []

  getSignal = () => {
    const controller = new AbortController()
    // останавливаем последний запрос
    this.signalStack[this.signalStack.length - 1]?.abort()

    // добавляем новый в стек
    this.signalStack.push(controller)
    return controller.signal
  }
  delSignal = () => {
    if (this.signalStack.length) this.signalStack.shift()
  }
  abortSignal = () => {
    if (this.signalStack.length) this.signalStack[this.signalStack.length - 1]?.abort()
  }
  getSignals = () => {
    return this.signalStack
  }
}

const ControllerSignalMap = new ControllerSignal()

export const fetchRests = createAsyncThunk(
  'map/fetchRests',
  async (
    page: number,
    { dispatch, getState },
  ): Promise<{ pagination: TypeMappingPaginationOutput; results: TypeMappingRestOutput[] }> => {
    const { center, bbox } = globalStore.getState().features.map.map
    const { southWest, northEast } = bbox
    const centerBeforeResponse = [...center]
    const timeBeforeRequest = Date.now()

    const response = await API.restaurant_list({
      params: { bbox: `${southWest.join(',')},${northEast.join(',')}`, page: page || 1, page_size: 30 },
      config: { signal: ControllerSignalMap.getSignal() },
    })
      .then((res) => {
        // Логика отправки запросов для получения след. пачки данных
        // + минимальный интервал между запросами (debounce)
        // + остановка запросов при превышении лимита
        // + проверка на наличие рестиков за пределами области просмотра (bbox)
        const timeAfterRequest = Date.now()
        const delayDebounce = 2000
        const timeDiff = timeAfterRequest - timeBeforeRequest

        const requestRestsNexPage = () => {
          if (!globalStore.getState().features.map.active) return

          const stateMap = globalStore.getState().features.map
          const centerAfterResponse = [...stateMap.map.center]
          const restsArray = Object.values(stateMap.restaurants.data)
          const hasRestsOutsideBbox = restsArray.some((el) => {
            const { northEast, southWest } = stateMap.map.bbox
            const [NELongitude, NELatitude] = northEast
            const [SWLongitude, SWLatitude] = southWest
            const [restLongitude, restLatitude] = el.address.coordinates

            const latitudeWithin = restLongitude < NELongitude && restLongitude > SWLongitude
            const longitudeWithin = restLatitude < NELatitude && restLatitude > SWLatitude

            return !(latitudeWithin && longitudeWithin)
          })
          const countRestsWithin = restsArray.length < stateMap.maxRests
          const centersSame = centerBeforeResponse.every((coords, idx) => coords === centerAfterResponse[idx])

          if (res.pagination.page.next.number && centersSame && (countRestsWithin || hasRestsOutsideBbox)) {
            dispatch(fetchRests(res.pagination.page.next.number))
          }
        }

        timeDiff >= delayDebounce ? requestRestsNexPage() : setTimeout(requestRestsNexPage, timeDiff)

        return res
      })
      .finally(() => ControllerSignalMap.delSignal())

    return response
  },
)

export const fetchRestsBySlugs = createAsyncThunk(
  'map/fetchRestsBySlugs',
  async (action: string[], { dispatch, getState }) => {
    return Promise.all(action.map((slug) => API.restaurant_read({ path: slug })))
  },
)

export const fetchRestGallery = createAsyncThunk('map/fetchRestGallery', async (id: number, { dispatch, getState }) => {
  return API.photo_list({ params: { page_size: 50, restaurant: Number(id) } }).then((res) => ({ ...res, restId: id }))
})
