import React, { FC } from 'react'

import { TypeMenuItem } from '@widgets/Restaurant/RestMenuItem/types/menu'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { RestMenuItem } from '@widgets/Restaurant/RestMenuItem'

interface Props {
  data: TypeMenuItem[]
  className?: string
}

const RestItemMenu: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  data,
  className = '',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <SectionCarousel countSlidesOnContainer={3} title={'Меню'} className={className} {...props}>
        <SectionCarousel.Items>
          {data.map((el, index) => {
            return <RestMenuItem sizes={'298x92 261x86'} key={el.id} {...el} analytic={{ section_index: index }} />
          })}
        </SectionCarousel.Items>
      </SectionCarousel>
    </ErrorBoundary>
  )
}

export default RestItemMenu
