import React, { useEffect, useRef } from 'react'
import { TypeMapContextState } from '@widgets/Restaurant/Map/MapContext'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { renderToString } from 'react-dom/server'
import { MapPinLabel } from '@widgets/Restaurant/Map/ui/MapPinLabel'
import { useRouter } from 'next/router'
import { HtmlMarker, Marker } from '@2gis/mapgl/global'
import IconPinRed from '@icons/map/pin-red.svg?url'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actionCreator'
import IconPinGreen from '@icons/map/pin-green.svg?url'
import IconPinGreenLight from '@icons/map/pin-green-light.svg?url'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

type TypeMapMarker = {
  marker: Marker | null
  label: HtmlMarker | null
}

export type TypeMarkersSources = {
  [id: number]: TypeMapMarker
}

/**
 * @description Хук для работы с маркерами карты
 */
export const useHandleMarkers = (mapInstance: TypeMapContextState) => {
  const { active, restaurants, selected } = useAppSelector((store) => store.features.map)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const selectedRest = selected.data

  /**
   * Объект для хранения экземпляров маркеров
   * В нем всегда должны быть актуальные данные, они должны быть синхронизированы с markers.list в store
   */
  const mapSourceMarkers = useRef<TypeMarkersSources>({})
  const mapHoverMarker = useRef<TypeMapMarker>()
  const mapActiveMarker = useRef<TypeMapMarker>()

  useEffect(() => {
    if (selectedRest != null) {
      const sourceMarker = mapSourceMarkers.current[selectedRest.id]
      setActiveMarker(sourceMarker)
    } else removeActiveMarker()
  }, [selectedRest])

  /**
   * Синхронизация со списком маркером в store (store -> markers -> list)
   * Создание нового и удаление лишнего
   */
  useEffect(() => {
    if (!mapInstance.mapglAPI) return

    const needToCreateMarkers = Object.values(restaurants.data).filter((rest) => !(rest.id in mapSourceMarkers.current))
    const needToDeleteMarkers = Object.keys(mapSourceMarkers.current).filter(
      (idOldMarker) => !(idOldMarker in restaurants.data),
    )

    if (needToDeleteMarkers) deleteMarkersById(needToDeleteMarkers)
    if (needToCreateMarkers.length) createMarkersByRests(needToCreateMarkers)
  }, [!!mapInstance.mapglAPI, restaurants.data])

  /**
   * Установка иконки выбранного ресторана
   */
  const setActiveMarker = (data: TypeMapMarker) => {
    if (mapActiveMarker.current?.marker === data?.marker || !data?.marker) return
    if (mapActiveMarker.current?.marker && mapActiveMarker.current.marker !== data.marker) {
      mapActiveMarker.current.marker.setIcon({ icon: mapActiveMarker.current.marker.userData.defaultIcon })
    }
    data.marker.setIcon({ icon: IconPinRed.src })
    mapActiveMarker.current = data
  }
  const removeActiveMarker = () => {
    if (mapActiveMarker.current) {
      mapActiveMarker.current.marker.setIcon({ icon: mapActiveMarker.current.marker.userData.defaultIcon })
    }
  }

  /**
   *  Удаление всех маркеров
   */
  const deleteAllMarkers = () => {
    Object.values(mapSourceMarkers.current).forEach((marker) => marker.marker.destroy())
    mapSourceMarkers.current = {}
  }

  // Удаление маркера по id
  const deleteMarkerById = (id: string | number) => {
    mapSourceMarkers.current[id].marker.destroy()
    mapSourceMarkers.current[id].label.destroy()
    delete mapSourceMarkers.current[id]
  }
  const deleteMarkersById = (ids: string[] | number[]) => {
    ids.forEach((id) => deleteMarkerById(id))
  }

  /**
   * Создание и вывод маркеров на карту по массиву ресторанов
   */
  const createMarkersByRests = (rests: TypeMappingRestOutput[]) => {
    rests.forEach((rest) => {
      if (!rest.address.coordinates) return

      const marker = new mapInstance.mapglAPI.Marker(mapInstance.mapCreated, {
        userData: {
          restData: rest,
          defaultIcon: rest.feedback.rating >= 6 ? IconPinGreen.src : IconPinGreenLight.src,
        },
        coordinates: rest.address.coordinates,
        interactive: true,
        icon: rest.feedback.rating >= 6 ? IconPinGreen.src : IconPinGreenLight.src,
      })

      mapSourceMarkers.current[rest.id] = {
        marker: marker,
        label: addMarkerInteractive(marker),
      }
    })
  }

  /**
   * Добавление интерактивности для маркеров
   */
  const addMarkerInteractive = (marker: Marker) => {
    const markerLabel = new mapInstance.mapglAPI.HtmlMarker(mapInstance.mapCreated, {
      coordinates: marker.userData.restData.address.coordinates,
      html: renderToString(<MapPinLabel restData={marker.userData.restData} />),
      anchor: [22, 24],
    })

    const markerLabelElement = markerLabel.getContent().querySelector('#marker-label')
    const markerLabelTitle = markerLabel.getContent().querySelector('#marker-label-title')
    const markerLabelPrice = markerLabel.getContent().querySelector('#marker-label-price')

    // Добавление события для перехода на карточку рестика
    markerLabelElement.addEventListener('click', (e) => {
      e.preventDefault()
      handleRestSelect()
    })

    const handleRestSelect = () => {
      dispatch(setRestSelectedAC({ restData: marker.userData.restData, eventFrom: 'pin', mapInstance }))
      setActiveMarker({ marker: marker, label: markerLabel })
    }

    const handleHoverMarker = (e) => {
      if (mapHoverMarker.current?.label === e.targetData) return
      // @ts-ignore
      markerLabelElement.style.display = 'block'
      mapHoverMarker.current = {
        marker,
        label: e.targetData,
      }

      markerLabelElement.addEventListener(
        'mouseleave',
        (e) => {
          // @ts-ignore
          e.currentTarget.style.display = 'none'
          mapHoverMarker.current = null
        },
        { once: true },
      )
    }

    marker.on('click', handleRestSelect)
    marker.on('mouseover', (e) => {
      const timerRunEvent = setTimeout(() => handleHoverMarker(e), 400)
      marker.on('mouseout', () => clearTimeout(timerRunEvent))
    })

    return markerLabel
  }
}
