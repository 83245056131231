import React, { FC, ReactNode, useContext } from 'react'
import s from './Img.module.sass'
import { CardTwoBlockContext } from '@shared/ui/Layout/CardTwoBlock/Context'
import Image from 'next/image'
import Link from 'next/link'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  img: string
  alt: string
  classNameWrapper?: string
  classNameContent?: string
  restId?: string
  onClickInsteadLink?: () => void
}

// TODO: добавить специфичности названию файла и компонента

const Img: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  classNameWrapper = '',
  classNameContent = '',
  restId,
  img,
  alt,
  onClickInsteadLink,
  ...props
}) => {
  const [context, setContext] = useContext(CardTwoBlockContext)

  const ComponentBase = () => (
    <div className={`${s.block} ${classNameWrapper}`} data-size={context.size} {...props}>
      <div className={s['img-wrapper']}>
        <Image layout={'fill'} className={s.img} src={img || getPlaceholderImage(restId) || ''} alt={alt} />
      </div>

      <div className={`${s.content} ${classNameContent}`}>{children}</div>
    </div>
  )

  return (
    <ErrorBoundary>
      {onClickInsteadLink ? (
        <div className={s.wrapper} onClick={onClickInsteadLink}>
          <ComponentBase />
        </div>
      ) : (
        <Link href={context.href} scroll={false}>
          <ComponentBase />
        </Link>
      )}
    </ErrorBoundary>
  )
}

export default Img
