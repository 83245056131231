import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import s from './MapRestCarouselMobileItem.module.sass'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { Metro } from '@shared/ui/Feedback/Metro'
import { Text } from '@shared/ui/Typography/Text'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { PriceRange } from '@shared/ui/Feedback/PriceRange'
import { useRouter } from 'next/router'
import { Rating } from '@shared/ui/Feedback/Rating'
import { Button } from '@shared/ui/Actions/Button'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { openBooking } from '@widgets/Restaurant/Booking'
import { EGG } from '@shared/api/analytics'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Favorite } from '@features/Favorite'

import Image from 'next/image'
import IconLike from '@icons/icon-like.svg'
import IconChevron from '@icons/map/chevron-mobile.svg'
import Link from 'next/link'

interface Props {
  data: TypeMappingRestOutput
}

const getAddressWithCity = (cityList, currentCityId, addressRest): string => {
  const cityName = cityList.find((city) => currentCityId === city.id)?.name

  return cityName ? `${cityName}, ${addressRest}` : addressRest
}

const MapRestCarouselMobileItem: FC<Props> = ({ data }) => {
  const globalStore = useAppSelector((store) => store.global)
  const dispatch = useAppDispatch()
  const { id, type, status, name, feedback, price, address, url, isShowUI, discounts, booking, images } = data
  const router = useRouter()
  const wrapperRef = useRef<HTMLDivElement>()
  const contentRef = useRef<HTMLDivElement>()
  const {
    img: isShowImg,
    likeButton: isShowLikeButton,
    reviewsButton: isShowReviewsButton,
    actionButtons: isShowActionButtons,
    rating: isShowRating,
    chevron: isShowChevron,
    price: isShowPrice,
    booking: isShowBooking,
  } = isShowUI

  const handleBooking = () => {
    if (booking.afisha.active) {
      dispatch(openBooking(data as TypeMappingRestOutput))
    } else if (booking.tomesto.active) {
      window.open(booking.tomesto.url, '_blank')
    }

    EGG.entity.restaurant.click_booking(data as TypeMappingRestOutput, { section_name: 'page' })
  }

  const img = images.cover.rest1894x1000 || images.main

  const imgAlt = name ? `Фото ресторана от Афиши — ${name}` : `Фото ресторана от Афиши`
  const getImg = useMemo(() => {
    if (!isShowUI.img || !img) return getPlaceholderImage()
    else return img
  }, [img])

  const isRestOpened = status.open || (!status.open && status.temporarily)
  const fullAddress = getAddressWithCity(globalStore.cityList, address.city.id, address.address)

  useEffect(() => {
    if (contentRef.current && wrapperRef.current) {
      wrapperRef.current.style.height = `${contentRef.current.clientHeight + 24 + (isShowUI.booking ? 60 : 0)}px`
    }
  }, [!!contentRef.current && !!wrapperRef.current])

  return (
    <div id={'rest-card-map-mobile'} className={s.wrapper} ref={wrapperRef}>
      <div className={s.card} data-has-buttons={isShowUI.booking}>
        <div className={s.main} ref={contentRef}>
          <div className={s.content}>
            <Link href={url.path} scroll={true}>
              <Text sizes={'ML'} medium tag={'h3'}>
                {name}
              </Text>
            </Link>

            <div className={s['rest-additional-info-wrap']}>
              {type && (
                <Text tag='span' className={s['rest-tag']} sizes='S'>
                  {type}
                </Text>
              )}

              {isShowPrice && (
                <>
                  <span className={s['price-dot']}></span>
                  <PriceRange
                    priceRange={price.level}
                    colorMode={'black'}
                    link={price.tag.url}
                    onClick={() => router.push(price.tag.url, price.tag.url, { scroll: false })}
                  />
                </>
              )}
            </div>

            <div className={s.address}>
              {address.metro ? (
                <ul className={s['metro-list']}>
                  {address.metro.slice(0, 2).map((metroItem) => (
                    <Metro
                      key={metroItem.id}
                      name={metroItem.name}
                      link={metroItem.url}
                      colors={metroItem.color}
                      id={s['metro-item']}
                    />
                  ))}
                </ul>
              ) : (
                <Text tag={'span'} sizes={'M S'}>
                  {fullAddress}
                </Text>
              )}
            </div>

            <div className={s['main-footer']}>
              {isShowUI.actionButtons && isShowUI.likeButton && (
                <Favorite
                  category={'restaurant'}
                  id={id}
                  analytic={{
                    data: data as TypeMappingRestOutput,
                    other: { section_name: 'page' },
                  }}>
                  <IconButton icon={IconLike} sizes='XS' mode='white-border' />
                </Favorite>
              )}
              {feedback.rating >= 4 && <Rating rating={feedback.rating} sizes={'S'} mode={'stroke'} />}
              {isShowUI.chevron && <IconChevron className={s.chevron} />}
            </div>
          </div>
          <div className={s['image-wrapper']}>
            <Link href={url.path} scroll={true}>
              <Image src={getImg} alt={imgAlt} width={68} height={68} className={s.image} />
            </Link>
          </div>
        </div>

        {isShowUI.booking && (
          <div className={s.buttons}>
            <Button sizes='L' mode={'black'} className={s['rest-booking-button']} onClick={handleBooking}>
              Забронировать
            </Button>
            {discounts.remarked && (
              <PromoBadgeCTA sizes={'L'} className={s['promo-badge']}>
                -{discounts.remarked}₽
              </PromoBadgeCTA>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MapRestCarouselMobileItem)
