import React, { FC, memo, useEffect, useId, useRef, useState } from 'react'
import s from './PlugAds.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Text } from '@shared/ui/Typography/Text'

import { useAppDispatch, useAppSelector } from '@app/model/store'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PlugAdsCard, PlugAdsCardSkeleton } from '@widgets/Common/PlugAds/ui/PlugAdsCard'
import { TypePlug } from '@widgets/Common/PlugAds/types/types'
import { Autoplay, Mousewheel, Navigation } from 'swiper/modules'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconArrow from '@icons/plugAdv/icon-arrow.svg'
import { addIdPlugElement, removeIdPlugElement } from '@widgets/Common/PlugAds/model/slice'

type TypeProps = {
  className: string
}

const PlugAds: FC<TypeProps> = ({ className = '' }) => {
  const plugId = useId()
  const plugIdValid = plugId.replaceAll(':', '')
  const plugsState = useAppSelector((store) => store.features.plugAds)
  const dispatch = useAppDispatch()
  const progressCircle = useRef(null)
  const [disabledLeft, setDisabledLeft] = useState(true)
  const [disabledRight, setDisabledRight] = useState(true)
  const [plugType, setPlugType] = useState<TypePlug>(null)
  const { isMobile, designEnv } = useClientResponsive()
  const wrapperRef = useRef<HTMLDivElement>()

  const onAutoplayTimeLeft = (s, time, progress) => {
    if (!plugsState.plugs[plugIdValid]?.list.length || !progressCircle.current?.style) return

    if (s.isEnd) {
      progressCircle.current.style.setProperty('--progress3', 0)
      progressCircle.current.style.opacity = 0
      return
    }
    progressCircle.current.style.setProperty('--progress3', 1 - progress)
    progressCircle.current.style.opacity = 1
  }

  useEffect(() => {
    if (plugsState.status === 'success' && plugType) dispatch(addIdPlugElement({ id: plugIdValid, type: plugType }))
    return () => {
      dispatch(removeIdPlugElement({ id: plugIdValid }))
    }
  }, [plugsState.status, plugType])

  useEffect(() => {
    if (isMobile) {
      setPlugType('mobile-slider')
      return
    }
    if (!wrapperRef.current) return
    const parentWidth = wrapperRef.current.parentElement.clientWidth
    setPlugType(parentWidth > 500 ? 'desktop-large' : 'desktop-shrink')
  }, [wrapperRef.current, designEnv])

  const updateDisabledArrows = (s) => {
    setDisabledLeft(s.isBeginning)
    setDisabledRight(s.isEnd)
  }

  const isShowComponent =
    !plugType ||
    plugsState.status == 'loading' ||
    (plugsState.status === 'success' && plugsState.plugs[plugIdValid]?.list.length)

  return (
    isShowComponent && (
      <ErrorBoundary>
        <div className={`${s.wrapper} ${className}`} ref={wrapperRef} data-plug-type={plugType}>
          <Text sizes={'S'} className={s.title}>
            Вместо рекламы
          </Text>
          <Swiper
            id={`plug-ads-${plugIdValid}`}
            autoplay={{
              delay: 12000,
              disableOnInteraction: false,
              stopOnLastSlide: true,
            }}
            longSwipes={false}
            navigation={{
              nextEl: `.arrow-right-${plugIdValid}`,
              prevEl: `.arrow-left-${plugIdValid}`,
            }}
            modules={[Mousewheel, Autoplay, Navigation]}
            speed={400}
            slidesPerView={'auto'}
            autoHeight={true}
            mousewheel={{
              enabled: true,
              forceToAxis: true,
              thresholdDelta: 30,
            }}
            spaceBetween={isMobile ? 8 : 11}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            onUpdate={updateDisabledArrows}
            onSlideChange={updateDisabledArrows}
            className={s.slider}>
            {plugsState.plugs[plugIdValid]?.list.length ? (
              plugsState.plugs[plugIdValid].list.map((el, idx) => (
                <SwiperSlide key={el.id}>
                  <PlugAdsCard data={el} plugType={plugType} />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <PlugAdsCardSkeleton plugType={plugType} />
              </SwiperSlide>
            )}
          </Swiper>
          {!isMobile && (
            <div className={s.arrows}>
              <div className={`arrow-left-${plugIdValid} ${s[`arrow-left-wrapper`]}`}>
                <IconButton
                  className={s[`arrow-left`]}
                  icon={IconArrow}
                  sizes={'L'}
                  mode={'white-shadow'}
                  disabled={disabledLeft}
                />
              </div>
              <div className={`arrow-right-${plugIdValid} ${s[`arrow-right-wrapper`]}`}>
                <IconButton
                  className={s[`arrow-right`]}
                  icon={IconArrow}
                  sizes={'L'}
                  mode={'white-shadow'}
                  disabled={disabledRight}
                />
                <div className={s[`autoplay-progress`]}>
                  <svg className='F0UM5'>
                    <radialGradient
                      id='radialGradient'
                      cx='0'
                      cy='1'
                      r='1'
                      gradientUnits='userSpaceOnUse'
                      gradientTransform='translate(23 23) rotate(-65) scale(24)'>
                      <stop stop-opacity='0.17'></stop>
                      <stop offset='1'></stop>
                      <stop offset='0.321099'></stop>
                    </radialGradient>
                    <circle
                      r='23'
                      cx='50%'
                      cy='50%'
                      fill='transparent'
                      className={s.circle}
                      ref={progressCircle}></circle>
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </ErrorBoundary>
    )
  )
}

export default memo(PlugAds)
