import React, { FC } from 'react'
import s from './Price.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { PriceRange } from '@shared/ui/Feedback/PriceRange'
import { Range } from '@widgets/Restaurant/ResturantCards/RestCard/type/type'
import { useRouter } from 'next/router'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  priceRange: Range<1, 5>
  priceLink?: string
  className?: string
}

const Price: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  priceRange,
  priceLink,
  className = '',
  ...props
}) => {
  const router = useRouter()
  const goToUrlPrice = () => {
    if (priceLink) router.push(priceLink, priceLink, { scroll: false })
  }

  return priceRange ? (
    <ErrorBoundary>
      <Text tag={'span'} sizes={'S'} className={s.dots}>
        ·
      </Text>
      <PriceRange
        priceRange={priceRange}
        colorMode={'black'}
        className={`${s.className}`}
        link={priceLink}
        {...props}
        onClick={goToUrlPrice}
      />
    </ErrorBoundary>
  ) : null
}

export default Price
