import React, { FC, useMemo } from 'react'
import s from './MapRestCard.module.sass'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { TagsByRestaurant } from '@widgets/Restaurant/TagsByRestaurant'
import { Metro } from '@shared/ui/Feedback/Metro'
import { Text } from '@shared/ui/Typography/Text'
import dynamic from 'next/dynamic'
import { RestaurantReviewsSkeleton } from '@widgets/Restaurant/RestaurantReviews'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MapRestMenu } from '../MapRestMenu'
import { PriceRange } from '@shared/ui/Feedback/PriceRange'
import { useRouter } from 'next/router'
import { Rating } from '@shared/ui/Feedback/Rating'
import { scrollToElem } from '@shared/lib/helpers/scrollToElem'
import { numWord } from '@widgets/Restaurant/Booking/lib/helper'
import { Title } from '@shared/ui/Typography/Title'
import { RestaurantSchedule } from '@widgets/Restaurant/RestaurantSchedule'
import { Button } from '@shared/ui/Actions/Button'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { openBooking } from '@widgets/Restaurant/Booking'
import { EGG } from '@shared/api/analytics'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconComment from '@icons/icon-comment.svg'
import IconLike from '@icons/icon-like.svg'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import Image from 'next/image'
import IconPhoto from '@icons/icon-photo.svg'
import IconChevron from '@icons/icon-chevron-big.svg'
import { Favorite } from '@features/Favorite'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import ContentLoader from 'react-content-loader'

const LazyReviews = dynamic(
  () => import('@widgets/Restaurant/RestaurantReviews').then((module) => module.RestaurantReviews),
  {
    loading: () => <RestaurantReviewsSkeleton />,
    ssr: false,
  },
)

const Skeleton: FC = () => {
  return (
    <div className={s.card}>
      <div className={s['image-wrapper']}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          width={'546px'}
          height='307px'
          className={s.image}>
          <rect x='0' y='0' rx='20' ry='20' width='100%' height='100%' />
        </ContentLoader>

        {/*{isShowUI.chevron && <IconChevron className={s.chevron} />}*/}
      </div>

      <div className={s['scroll-content']}>
        <div className={s.header}>
          <div className={s['header-top']}>
            <div className={s['rest-additional-info-wrap']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='133px'
                height='18px'>
                <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
              </ContentLoader>
            </div>

            <div className={s['rating-wrapper']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='131px'
                height='26px'>
                <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
              </ContentLoader>
            </div>
          </div>

          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width='250px'
            height='36px'>
            <rect x='0' y='0' rx='15' ry='15' width='100%' height='100%' />
          </ContentLoader>

          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width='202px'
            height='20px'
            className={s['schedule-button']}>
            <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
          </ContentLoader>

          <div className={s['main-buttons-wrapper']}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width='442px'
              height='56px'
              className={s['rest-booking-button']}>
              <rect x='0' y='0' rx='30' ry='30' width='100%' height='100%' />
            </ContentLoader>

            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width='56px'
              height='56px'>
              <rect x='0' y='0' rx='30' ry='30' width='100%' height='100%' />
            </ContentLoader>
          </div>
        </div>
        <div className={s.meta}>
          <div className={s.table}>
            {/* Адрес */}
            <div className={`${s['table-cell']} ${s['table-cell--long']}`}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='80px'
                height='18px'>
                <rect x='0' y='0' rx='6' ry='6' width='100%' height='100%' />
              </ContentLoader>
              <div className={s['cell-data']}>
                <ContentLoader
                  speed={SKELETON_SPEED}
                  backgroundColor={SKELETON_MAIN_COLOR}
                  foregroundColor={SKELETON_FOREGROUND_COLOR}
                  style={SKELETON_STYLES}
                  width='300px'
                  height='44px'>
                  <rect x='0' y='0' rx='9' ry='9' width='80%' height='20px' />
                  <rect x='0' y='24' rx='9' ry='9' width='50%' height='20px' />
                </ContentLoader>
              </div>
            </div>

            <div className={s['table-cell']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='80px'
                height='18px'>
                <rect x='0' y='0' rx='6' ry='6' width='100%' height='100%' />
              </ContentLoader>
              <div className={s['cell-data']}>
                <ContentLoader
                  speed={SKELETON_SPEED}
                  backgroundColor={SKELETON_MAIN_COLOR}
                  foregroundColor={SKELETON_FOREGROUND_COLOR}
                  style={SKELETON_STYLES}
                  width='170px'
                  height='20px'>
                  <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
                </ContentLoader>
              </div>
            </div>

            <div className={s['table-cell']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='80px'
                height='18px'>
                <rect x='0' y='0' rx='6' ry='6' width='100%' height='100%' />
              </ContentLoader>
              <div className={s['cell-data']}>
                <ContentLoader
                  speed={SKELETON_SPEED}
                  backgroundColor={SKELETON_MAIN_COLOR}
                  foregroundColor={SKELETON_FOREGROUND_COLOR}
                  style={SKELETON_STYLES}
                  width='170px'
                  height='20px'>
                  <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
                </ContentLoader>
              </div>
            </div>

            <div className={s['table-cell']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='80px'
                height='18px'>
                <rect x='0' y='0' rx='6' ry='6' width='100%' height='100%' />
              </ContentLoader>
              <div className={s['cell-data']}>
                <ContentLoader
                  speed={SKELETON_SPEED}
                  backgroundColor={SKELETON_MAIN_COLOR}
                  foregroundColor={SKELETON_FOREGROUND_COLOR}
                  style={SKELETON_STYLES}
                  width='170px'
                  height='20px'>
                  <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
                </ContentLoader>
              </div>
            </div>

            <div className={s['table-cell']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='80px'
                height='18px'>
                <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
              </ContentLoader>
              <div className={s['cell-data']}>
                <ContentLoader
                  speed={SKELETON_SPEED}
                  backgroundColor={SKELETON_MAIN_COLOR}
                  foregroundColor={SKELETON_FOREGROUND_COLOR}
                  style={SKELETON_STYLES}
                  width='170px'
                  height='20px'>
                  <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
                </ContentLoader>
              </div>
            </div>
          </div>

          {/*<TagsByRestaurant tags={tags.searchable} restId={id} id={s.tags} />*/}
        </div>
      </div>
    </div>
  )
}

export default Skeleton
