import React, { FC, memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import s from './MapRestGrid.module.sass'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { Title } from '@shared/ui/Typography/Title'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { MapButtonActionForAside } from '@widgets/Restaurant/Map/ui/MapButtonActionForAside'
import { Button } from '@shared/ui/Actions/Button'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actionCreator'
import IconVisibleGrid from '@icons/icon-arrow-small.svg'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { Text } from '@shared/ui/Typography/Text'
import { useMapTitle } from '@widgets/Restaurant/Map/hooks/useMapTitle'

interface Props {
  className?: string
}

const MapRestGrid: FC<Props> = ({ className = '' }) => {
  const { restaurants, nearestCityId } = useAppSelector((store) => store.features.map)
  const [visibleGrid, setVisibleGrid] = useState(true)
  const [restaurantsOutput, setRestaurantsOutput] = useState([])
  const [mapInstance] = useContext(MapContext)
  const listRef = useRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const elementGradient = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!elementGradient.current) return
    const handleScrollList = (e) => {
      const isShowGradient = !!e.target.scrollTop
      elementGradient.current.dataset.visible = String(isShowGradient)
    }

    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleScrollList)
    }
    return () => {
      listRef.current?.removeEventListener('scroll', handleScrollList)
    }
  }, [!!elementGradient])

  useEffect(() => {
    const array = Object.values(restaurants.data).filter((rest) => rest.address.city.id === nearestCityId)
    setRestaurantsOutput(array)
  }, [restaurants.data, nearestCityId])

  const handleClickCard = (restData) => {
    dispatch(setRestSelectedAC({ restData, eventFrom: 'card', mapInstance }))
  }

  const mapTitle = useMapTitle()

  return (
    <div className={`${s.wrapper} ${className}`}>
      {visibleGrid ? (
        <div className={s['grid-container']}>
          <MapButtonActionForAside onClick={() => setVisibleGrid(false)} icon={IconVisibleGrid} className={s.icon} />

          <div className={s.grid}>
            <Title sizes={'h2'} className={s.title}>
              {mapTitle}
            </Title>

            <div className={s['list-wrapper']}>
              <div className={s.gradient} ref={elementGradient}></div>
              <div className={s.list} ref={listRef}>
                <>
                  {restaurantsOutput.length ? (
                    restaurantsOutput.map((rest, index) => (
                      <RestCard
                        sizes={'M'}
                        key={rest.id}
                        onClickInsteadLink={() => handleClickCard(rest)}
                        type={'list'}
                        data={rest}
                        analytic={{
                          section_name: 'map',
                          section_index: index,
                          advertCardPlaceType: 'search',
                        }}
                      />
                    ))
                  ) : Object.keys(restaurants.data).length || restaurants.status === 'loading' ? (
                    Array.from(Array(4).keys()).map((i, idx) => <RestCardSkeleton sizes={'M'} key={idx} />)
                  ) : (
                    <div className={s['not-found']}>
                      <Text sizes={'M'}>Рестораны не найдены</Text>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Button sizes={'M'} mode={'white'} onClick={() => setVisibleGrid(true)} id={s['button-open-grid']}>
          <Text sizes={'S'}>Показать список</Text>
          <IconVisibleGrid />
        </Button>
      )}
    </div>
  )
}

export default memo(MapRestGrid)
