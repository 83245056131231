import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchRests, fetchRestGallery } from '@widgets/Restaurant/Map/model/thunks'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { TypeInitialState, TypeMapBbox, TypeMapCoords, TypeMapStatus } from '@widgets/Restaurant/Map/model/types'
import { PhotoList } from '@shared/api/types/swaggerTypes'
import { distanceBetweenLocations } from '@widgets/Restaurant/Map/helpers/getDistanceBetweenCoords'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'

const initialState: TypeInitialState = {
  active: false,
  filters: null,
  maxRests: 100,
  nearestCityId: null,

  // полные данные для вывода листинга, слайдера
  restaurants: {
    status: 'empty',
    data: {},
  },

  galleries: {
    status: 'empty',
    data: {},
  },

  selected: {
    status: 'empty',
    data: null,
  },

  map: {
    bbox: null,
    center: null,
    zoom: null,
  },
}

const Map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActiveMap(state, action) {
      state.active = action.payload
    },

    setMapCenter(state, action: PayloadAction<TypeMapCoords>) {
      state.map.center = action.payload
    },
    setMapBbox(state, action: PayloadAction<TypeMapBbox>) {
      state.map.bbox = action.payload
    },
    setMapZoom(state, action: PayloadAction<number>) {
      state.map.zoom = action.payload
    },

    // restaurants
    addRestaurantsData(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      action.payload.forEach((rest) => {
        state.restaurants.data[rest.id] = rest
      })
    },
    deleteRestaurantsData(state, action: PayloadAction<number[]>) {
      // todo
    },
    setRestaurantsStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.restaurants.status = action.payload
    },

    // selected
    setSelectedData(state, action: PayloadAction<TypeMappingRestOutput>) {
      state.selected.data = action.payload
    },
    setSelectedStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.selected.status = action.payload
    },

    // galleries
    addGalleriesDataItem(state, action: PayloadAction<{ restId: number; media: PhotoList[] }>) {
      if (!action.payload.restId || !action.payload.media) return
      state.galleries.data[action.payload.restId] = action.payload.media
    },
    deleteGalleriesDataItem(state, action: PayloadAction<{ restId: number; media: PhotoList[] }>) {
      if (!action.payload.restId || !action.payload.media) return
      state.galleries.data[action.payload.restId] = action.payload.media
    },
    setGalleriesStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.selected.status = action.payload
    },

    resetMap() {
      return initialState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRests.pending, (state, action) => {
        state.restaurants.status = 'loading'
      })
      .addCase(fetchRests.fulfilled, (state, action) => {
        if (!state.active) return
        action.payload.results.map((rest) => {
          if (rest.id in state.restaurants.data) return
          state.restaurants.data[rest.id] = rest
        })

        const restArray = Object.values(state.restaurants.data)

        // Удаление самых дальних объектов
        const arrayDistances = restArray.map((el) => {
          const [centerLongitude, centerLatitude] = state.map.center
          const [restLongitude, restLatitude] = el.address.coordinates
          return {
            id: el.id,
            distance: distanceBetweenLocations(
              { latitude: centerLatitude, longitude: centerLongitude },
              { latitude: restLatitude, longitude: restLongitude },
            ),
          }
        })

        arrayDistances.sort((a, b) => a.distance - b.distance)

        // Узнаем текущий просматриваемый город, прочитав первый (он же ближайший к центру карты) ресторан
        state.nearestCityId = restArray.length ? state.restaurants.data[arrayDistances[0].id].address.city.id : null

        for (let i = 0; i < arrayDistances.length; i++) {
          const getRestId = arrayDistances[i].id
          if (i >= state.maxRests || arrayDistances[i].distance > 50) delete state.restaurants.data[getRestId]
        }

        state.restaurants.status = 'success'
      })
      .addCase(fetchRests.rejected, (state, action) => {
        state.restaurants.data = {}
        state.restaurants.status = 'error'
      })

      .addCase(fetchRestGallery.pending, (state, action) => {
        state.restaurants.status = 'loading'
      })
      .addCase(fetchRestGallery.fulfilled, (state, action) => {
        const { restId, ...response } = action.payload

        state.galleries.data[restId] = response.MAPPING.results
        state.galleries.status = 'success'
      })
      .addCase(fetchRestGallery.rejected, (state, action) => {
        state.restaurants.status = 'error'
      })
  },
})

export const {
  setActiveMap,
  setMapCenter,
  setMapBbox,
  setMapZoom,
  addRestaurantsData,
  deleteRestaurantsData,
  setRestaurantsStatus,
  setSelectedData,
  setSelectedStatus,
  addGalleriesDataItem,
  deleteGalleriesDataItem,
  setGalleriesStatus,
  resetMap,
} = Map.actions

export default Map.reducer
