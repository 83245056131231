import React, { FC, memo, useMemo } from 'react'
import s from './ButtonMap.module.sass'
import { Button } from '@shared/ui/Actions/Button'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'
import IconPin from '@icons/map/pin-in-button.svg'
import MapBackgroundButton from '@images/map-background.png'

interface Props {
  onClick: () => void
  className?: string
  active?: boolean
}

export const ButtonMap: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  onClick,
  className = '',
  active = false,
  ...props
}) => {
  const getClassButton = useMemo(() => {
    return active ? s.button_active : ''
  }, [active])

  return (
    <ErrorBoundary>
      <Button
        id={s.button}
        sizes='M'
        mode='white'
        style={{ backgroundImage: `url('${MapBackgroundButton.src}')` }}
        className={`${getClassButton} ${className}`}
        onClick={onClick}
        {...props}>
        <IconPin />
      </Button>
    </ErrorBoundary>
  )
}

export default memo(ButtonMap)
