import React, { FC } from 'react'
import s from './TagsByRestaurant.module.sass'
import { BtnFilterAfishaTag } from '@shared/ui/Form/Single/Filters/AfishaTag'
import { BtnFilterTag } from '@shared/ui/Form/Single/Filters/DefaultTag'
import { useKeenSlider } from 'keen-slider/react'
import { findDataTagForAnalyticBySlug } from '@features/Restaurant/RestaurantFilters/helpers/helper'
import { EGG } from '@shared/api/analytics'
import { useRouter } from 'next/router'
import { TypeMappingRestTag } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  tags: TypeMappingRestTag[]
  restId: number
  className?: string
}

const TagsByRestaurant: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  tags,
  restId,
  className = '',
  ...props
}) => {
  const router = useRouter()

  const [sliderRef] = useKeenSlider({
    mode: 'free',
    slides: {
      perView: 'auto',
      spacing: 6,
    },
    selector: `.${s.tag}`,
  })

  const handleOpenTagPage = (tagElem) => {
    if (tagElem.url) {
      const dataTag = findDataTagForAnalyticBySlug(tagElem.slug)
      const urlSplit = tagElem.url.split('/')
      const getSlug = urlSplit[urlSplit.length - 2]
      EGG.common.tag_click({
        filter_apply_type: dataTag.type,
        filter_apply_value: dataTag.value || getSlug,
      })

      router.push(tagElem.url, tagElem.url, { scroll: false })
    }
  }

  return (
    <div className={`${s['tag-wrapper']} ${className}`} {...props}>
      <ul className={`keen-slider`} ref={sliderRef} key={restId}>
        {!!tags.length &&
          tags.map((el, i) => {
            return (
              <div className={s.tag} key={`${restId}-${el.id}`}>
                {/*// @ts-ignore*/}
                {el.has_selection ? (
                  <BtnFilterAfishaTag key={el.id} onClick={() => handleOpenTagPage(el)}>
                    {el.name}
                  </BtnFilterAfishaTag>
                ) : (
                  <BtnFilterTag key={el.id} onClick={() => handleOpenTagPage(el)}>
                    {el.name}
                  </BtnFilterTag>
                )}
              </div>
            )
          })}
      </ul>
    </div>
  )
}

export default TagsByRestaurant
