// Возвращает классы размеров
// sizesString - 'M'(global), 'M S'(desktop, mobile), 'XL M S'(desktop, tablet, mobile)

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

/**
 * @function getClassSizes - TODO описание
 * @todo устарело, заменить везде на useCurrentSize
 */
export const getClassSizes = (styles: object, mainClass: string, sizesString: string) => {
  let result = ''

  const sizeArray = sizesString.split(' ')

  // const isSizeGlobal = sizeArray.length === 1
  // const isSizeDesktopMobile = sizeArray.length === 2
  const isSizeDesktopTabletMobile = sizeArray.length === 3

  const classes = {
    desktop: sizeArray[0],
    tablet: isSizeDesktopTabletMobile ? sizeArray[1] : sizeArray[0],
    mobile: sizeArray[2] || sizeArray[1] || sizeArray[0],
  }

  Object.keys(classes).forEach((key) => {
    // @ts-ignore
    result = `${result} ${styles[`${mainClass}--size-${key}-${classes[key]}`]}`
  })

  return result
}

/**
 * @function useCurrentSize
 * Принимает в себя строку с размерами перечисленными через пробел.
 * Возвращает [currentSize, ...allSizes]
 * currentSize - текущее значение размера для текущего разрешения экрана (isDesktop, isTablet и т.д.)
 * allSizes - остальные значения размеров для остальных разрешений
 * @description 'M' -> desktop-large - M, desktop - M, tablet - M, mobile - M
 * @description 'M S' -> desktop-large - M, desktop - M, tablet - M, mobile - S
 * @description 'XL M S' -> desktop-large - XL, desktop - XL, tablet - M, mobile - S
 * @description 'XL L M S' -> desktop-large - XL, desktop - L, tablet - M, mobile - S
 */
export const useCurrentSize = (sizes: string): string[] => {
  const { isDesktopLarge, isDesktop, isTablet, isMobile } = useClientResponsive()
  const sizesArr = sizes.split(' ')
  const formattedSizeArray = []

  for (let i = 4; i > 0; i--) {
    const size = sizesArr[sizesArr.length - i < 0 ? 0 : sizesArr.length - i]
    formattedSizeArray.push(size)
  }

  if (isDesktopLarge) return [formattedSizeArray[0], formattedSizeArray]
  else if (isDesktop) return [formattedSizeArray[1], formattedSizeArray]
  else if (isTablet) return [formattedSizeArray[2], formattedSizeArray]
  else if (isMobile) return [formattedSizeArray[3], formattedSizeArray]
}
