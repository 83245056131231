import React, { FC, memo, useMemo } from 'react'
import s from './MapRestCardCover.module.sass'
import Image from 'next/image'
import IconPhoto from '@icons/icon-photo.svg'
import { Text } from '@shared/ui/Typography/Text'
import { useAppSelector } from '@app/model/store'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconArrow from '@icons/icon-arrow-slider.svg'

const MapRestCardCover: FC = () => {
  const mapStore = useAppSelector((store) => store.features.map)
  const restData = mapStore.selected.data
  const galleryData = mapStore.galleries.data[restData.id]

  const img = restData.images.cover.rest1064x1064 || restData.images.cover.rest1894x1000 || restData.images.main
  const imgAlt = restData.name ? `Фото ресторана от Афиши — ${restData.name}` : `Фото ресторана от Афиши`
  const getImg = useMemo(() => {
    if (!restData.isShowUI.img || !img) return getPlaceholderImage()
    else return img
  }, [img])

  const getArrImages = useMemo(() => {
    const mainImage = getImg
    const anotherImages = galleryData?.length > 1 ? galleryData.map((img) => img.cover_images['400x300']).slice(1) : []
    return [mainImage, ...anotherImages]
  }, [galleryData])

  return (
    <div className={s['image-wrapper']}>
      {galleryData ? (
        <Carousel>
          <div className={s.arrows}>
            <Carousel.Arrow direction={'prev'} className={s.arrow}>
              <IconButton sizes={'M'} mode={'white'} icon={IconArrow} />
            </Carousel.Arrow>
            <Carousel.Arrow direction={'next'} className={s.arrow}>
              <IconButton sizes={'M'} mode={'white'} icon={IconArrow} />
            </Carousel.Arrow>
          </div>
          <Carousel.Slider spacingMobile={4} spacingDesktop={4}>
            {getArrImages.map((imgSrc) => (
              <Image key={imgSrc} src={imgSrc} alt={imgAlt} width={546} height={307} className={s.image} />
            ))}
          </Carousel.Slider>
        </Carousel>
      ) : (
        <Image src={getImg} alt={imgAlt} width={546} height={307} className={s.image} />
      )}

      {!!galleryData?.length && (
        <div className={s['badge-gallery']}>
          <IconPhoto />
          <Text sizes={'S'} className={s['badge-gallery-count']}>
            {galleryData.length}
          </Text>
        </div>
      )}
    </div>
  )
}

export default memo(MapRestCardCover)
