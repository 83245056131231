import s from './EventCard.module.sass'

import React, { FC, useRef } from 'react'
import Image from 'next/image'

import { Favorite } from '@features/Favorite'

import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { ChevronWithTooltip } from '@shared/ui/Feedback/ChevronWithTooltip'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { EGG } from '@shared/api/analytics'

import IconChevron from '@icons/icon-chevron-medium.svg'
import IconLike from '@icons/icon-like.svg'

interface Props {
  data: any
  analytic: PickAnalytic<'section_index' | 'object_id'>
}

const PAYMENT_WIDGET_KEY = '0d8c1d07-209c-497f-9a32-620dd437758a'
const UTM_ACTIVITY = '?utm_source=restaurants&utm_medium=referral&utm_campaign=afisha_events'

const EventCard: FC<Props> = ({ data, analytic }) => {
  const element = useRef()

  const activityType =
    data?.input?.concert ||
    data?.input?.exhibition ||
    data?.input?.movie ||
    data?.input?.performance ||
    data?.input?.sport

  const id = activityType?.id
  const idNumber = id ? parseInt(id.match(/\d+/)) : null
  const imageSrc = activityType?.image?.url || getPlaceholderImage(id)
  const isAfishaChoice = !!activityType?.afishaChoice

  const handleOpenActivityLink = (dataInput, ev) => {
    try {
      ev.stopPropagation()

      const thisURL =
        dataInput?.concert?.url ||
        dataInput?.exhibition?.url ||
        dataInput?.movie?.url ||
        dataInput?.performance?.url ||
        dataInput?.sport?.url ||
        null

      EGG.pages.RestItem.event_card_click(analytic)

      const needToOpenUrl = thisURL && ev.target.dataset.button !== 'x-open-payment'
      const target = needToOpenUrl ? 'https://afisha.ru' + thisURL + UTM_ACTIVITY : null

      if (needToOpenUrl) {
        window.location.href = target
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @function handlePaymentAction — работа с window.ticketManager
   * @todo вынести в redux для вызова session с покупками
   * @todo добавить callback, если ticketManager не загрузился,
   *    вместо этого открывать страницу покупки на Афише
   */
  const handleOpenWidget = (data) => {
    EGG.pages.RestItem.event_price_click(analytic)

    const sessionId = data?.input?.sessions[0]?.sessionSourceID
    // @ts-ignore
    sessionId && window.ticketManager.session(PAYMENT_WIDGET_KEY, sessionId)
  }

  useIntersectionAnalytic(element, () => {
    EGG.pages.RestItem.event_card_view(analytic)
  })

  const favCategory = data?.input?.concert
    ? 'concert'
    : data?.input?.exhibition
    ? 'exhibition'
    : data?.input?.movie
    ? 'movie'
    : data?.input?.performance
    ? 'performance'
    : data?.input?.sport
    ? 'sport'
    : ''

  return (
    <ErrorBoundary>
      <div className={s['payment-item']} data-id={id} ref={element}>
        {isAfishaChoice && <ChevronWithTooltip icon={IconChevron} className={s['payment-item-afisha-selected']} />}

        <div className={s['payment-item-wrap']} onClick={(ev) => handleOpenActivityLink(data.input, ev)}>
          {imageSrc && (
            <Image
              className={s['payment-item-picture']}
              alt={
                data?.input?.concert?.name ||
                data?.input?.exhibition?.name ||
                data?.input?.movie?.name ||
                data?.input?.performance?.name ||
                data?.input?.sport?.name ||
                ''
              }
              src={imageSrc}
              quality={100}
              width={297}
              height={198}
              fill={false}
            />
          )}
        </div>

        {data.amount && (
          <div className={s['payment-item-open-modal-wrap']}>
            <button
              type='button'
              data-button='x-open-payment'
              onClick={(ev) => handleOpenWidget(data)}
              className={s['payment-item-open-modal']}>
              от {data.amount} ₽
            </button>

            <Favorite
              // @ts-ignore
              category={favCategory}
              id={idNumber}
              analytic={{
                data:
                  data?.input?.concert ||
                  data?.input?.exhibition ||
                  data?.input?.movie ||
                  data?.input?.performance ||
                  data?.input?.sport,
                other: { section_name: 'events' },
              }}
              className={s.favorite}>
              <IconButton icon={IconLike} sizes='S' mode={'white'} />
            </Favorite>
          </div>
        )}

        {data.name && (
          <h3 className={s['payment-name']} onClick={(ev) => handleOpenActivityLink(data.input, ev)}>
            {data.name}
          </h3>
        )}

        {data.type && <span className={s['payment-genre']}>{data.type}</span>}

        {data.timeDescr && <span className={s['payment-time-descr']}>{data.timeDescr}</span>}
      </div>
    </ErrorBoundary>
  )
}

export default EventCard
