import React from 'react'
import * as mapgl from '@2gis/mapgl/types'
import { Map } from '@2gis/mapgl/types/map'

export type TypeMapContextState = {
  mapglAPI: typeof mapgl
  mapCreated: Map
}

export type TypeMapContextSetState = React.Dispatch<any>

type TypeContext = [TypeMapContextState, TypeMapContextSetState]

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const MapContext = React.createContext<TypeContext>([{ mapglAPI: null, mapCreated: null }, () => {}])
