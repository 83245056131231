import React, { FC, memo, useMemo, useRef } from 'react'
import s from './RestMenuItem.module.sass'
import Image from 'next/image'
import { Text } from '@shared/ui/Typography/Text'
import { TypeMenuItem } from '@widgets/Restaurant/RestMenuItem/types/menu'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'
import { useCurrentSize } from '@shared/lib/helpers/classes'

const RestMenuItem: FC<TypeMenuItem> = ({ id, type, img, href, analytic, sizes }) => {
  const [currentSize] = useCurrentSize(sizes)
  const wrapper = useRef()
  const extFile = useMemo(() => {
    const m = href.match(/\.([^.]+)$/)
    return m ? m[1] : 'Формат не определен'
  }, [href])

  console.log('RestMenuItem', sizes, currentSize)
  const sizesTitle = {
    '298x92': 'L',
    '261x86': 'ML',
    '245x76': 'M',
  }

  const sizesImage = {
    '298x92': 92,
    '261x86': 86,
    '245x76': 76,
  }

  // @ts-ignore
  const getSizeTitle = sizesTitle[currentSize]
  // @ts-ignore
  const getSizeImage = sizesImage[currentSize]

  useIntersectionAnalytic(wrapper, () =>
    EGG.pages.RestItem.menu_item_view({
      menu_type: type,
      object_id: id,
      object_type: 'menu',
      section_index: analytic?.section_index || 0,
    }),
  )

  const handleClickMenu = () => {
    EGG.pages.RestItem.menu_item_click({
      menu_type: type,
      object_id: id,
      object_type: 'menu',
      section_index: analytic?.section_index || 0,
    })
  }

  return (
    <ErrorBoundary>
      <a
        ref={wrapper}
        href={href}
        className={s.menu}
        target='_blank'
        rel='noreferrer'
        data-menu-item-id={id}
        data-size={currentSize}
        onClick={handleClickMenu}>
        <Text sizes={getSizeTitle} className={s.title}>
          {type}
        </Text>

        <Text sizes={'S'} className={s.ext} uppercase>
          {extFile}
        </Text>

        <div className={s['image-wrapper']}>
          <Image
            src={img || ''}
            width={getSizeImage}
            height={getSizeImage}
            className={s['image-elem']}
            alt={`Ссылка на меню — ${type}`}
          />
        </div>
      </a>
    </ErrorBoundary>
  )
}

export default memo(RestMenuItem)
