const WEEKDAY_NAMES = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

const MONTH_NAMES = ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек']

/**
 * @function getWordMonth - возвращает склоняемое название месяца по ID (0...11)
 */
const getWordMonth = function (monthId) {
  let outMonth = null
  switch (monthId) {
    case 0:
      outMonth = 'января'
      break
    case 1:
      outMonth = 'февраля'
      break
    case 2:
      outMonth = 'марта'
      break
    case 3:
      outMonth = 'апреля'
      break
    case 4:
      outMonth = 'мая'
      break
    case 5:
      outMonth = 'июня'
      break
    case 6:
      outMonth = 'июля'
      break
    case 7:
      outMonth = 'августа'
      break
    case 8:
      outMonth = 'сентября'
      break
    case 9:
      outMonth = 'октября'
      break
    case 10:
      outMonth = 'ноября'
      break
    case 11:
      outMonth = 'декабря'
      break
    default:
      outMonth = ''
      break
  }
  return outMonth
}

/**
 * @function mappingPaymentItem - маппинг данных для генерации событий
 */
const mappingPaymentItem = (activityData) => {
  console.log('!!! activityData', activityData)
  try {
    return activityData.map((thisActivity) => {
      const session = thisActivity.sessions.length > 0 ? thisActivity.sessions[0] : null
      const thisDate = session ? new Date(session.dateTime) : null

      const activityType =
        thisActivity.concert ||
        thisActivity.exhibition ||
        thisActivity.movie ||
        thisActivity.performance ||
        thisActivity.sport

      const thisGenre = activityType.genres?.length > 0 ? activityType.genres[0].name : ''

      return {
        input: thisActivity,
        id: activityType.id || null,
        name: activityType.name || null,
        type: thisGenre,
        amount: session?.minPrice || null,
        timeDescr: thisDate ? thisDate.getDate() + ' ' + getWordMonth(thisDate.getMonth()) : null,
      }
    })
  } catch (e) {
    console.error(e)
    return false
  }
}

/**
 * @function generateCalendarDates - генерация списка дней, начиная с текущей даты
 */
const generateCalendarDates = (TODAY_STARTING_DATE, daysCount = 30) => {
  const createDayObject = (date, hasMonth) => ({
    value: date.getTime(),
    dayOfWeek: WEEKDAY_NAMES[date.getDay()],
    dayOfMonth: `${addZero(date.getDate())}`,
    monthAbr: MONTH_NAMES[date.getMonth()],
    isHoliday: date.getDay() === 0 || date.getDay() === 6,
    hasMonthLabel: hasMonth,
  })
  return Array.from({ length: daysCount }).map((_, i) => {
    const thisDay = new Date(TODAY_STARTING_DATE)
    thisDay.setDate(TODAY_STARTING_DATE.getDate() + i)
    const isFirstInArrayOrMonth = i == 0 || thisDay.getDate() == 1
    return createDayObject(thisDay, isFirstInArrayOrMonth)
  })
}

/**
 * @function generateRequestDate - converts: 1708300800000 => "2024-02-19"
 */
const generateRequestDate = (TODAY_STARTING_DATE, timestamp) => {
  const thisDate = new Date(timestamp || TODAY_STARTING_DATE)
  const year = thisDate.getFullYear()
  const month = (thisDate.getMonth() + 1).toString().padStart(2, '0')
  const day = thisDate.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

/**
 * @function filterDaysToSelect - к массиву с объектами, добавляет параметр isDisabled,
 *    если в данном массиве поле .value с датой не встречается в фильтрующем availableDays
 */
const filterDaysToSelect = (inputDays, availableDays) => {
  return inputDays.map((thisDay) => {
    const outputDay = thisDay
    const isDisabled = availableDays
      ? availableDays.some((str) => {
          const isAnyPaymentExist = str.toLowerCase() === generateRequestDate(thisDay.value)
          return isAnyPaymentExist
        })
      : null
    outputDay['isDisabled'] = !isDisabled
    return outputDay
  })
}

/**
 * @function addZero - TODO описание
 */
const addZero = (number) => `${`00${number}`.slice(-2)}`

export { mappingPaymentItem, generateCalendarDates, generateRequestDate, filterDaysToSelect }
