import { useMemo } from 'react'
import { useAppSelector } from '@app/model/store'

export const useMapTitle = () => {
  const cityList = useAppSelector((store) => store.global.cityList)
  const nearestCityId = useAppSelector((store) => store.features.map.nearestCityId)

  return useMemo(() => {
    try {
      if (!nearestCityId) return `Кафе и рестораны`
      const cityGenitiveCase = cityList.find((city) => city.id == nearestCityId).genitive_case
      return `Кафе и рестораны ${cityGenitiveCase}`
    } catch (e) {
      return 'Кафе и рестораны'
    }
  }, [nearestCityId])
}
