import React, { FC } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { TypePlug } from '@widgets/Common/PlugAds/types/types'
import s from '@widgets/Common/PlugAds/ui/PlugAdsCard/PlugAdsCard.module.sass'

interface Props {
  plugType: TypePlug
}

const Skeleton: FC<Props> = ({ plugType }) => {
  return (
    <ErrorBoundary>
      <div className={s.card} data-plug-type={plugType}>
        <div className={s.image}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width={plugType == 'desktop-large' ? '200px' : '100%'}
            height={plugType == 'desktop-large' ? '200px' : '160px'}>
            <rect x='0' y='0' rx='16' ry='16' width='100%' height='100%' />
          </ContentLoader>
        </div>
        <div className={s.content}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width={'130px'}
            height={'20px'}>
            <rect x='0' y='0' rx='12' ry='12' width='100%' height='100%' />
          </ContentLoader>
          <div className={s['content-footer']}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width={'60px'}
              height={'18px'}>
              <rect x='0' y='0' rx='10' ry='10' width='100%' height='100%' />
            </ContentLoader>

            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width={'109px'}
              height={'40px'}>
              <rect x='0' y='0' rx='20' ry='20' width='100%' height='100%' />
            </ContentLoader>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
