/**
 * @fsd_page RestItem - страница-карточка ресторана
 *   - открытая форма брони c параметром ?reserve
 */

// TODO: change tags & text "section-meta" for right SEO data

import s from './RestItem.module.sass'

import dynamic from 'next/dynamic'
import React, { FC, useEffect, useRef } from 'react'

import { useAppSelector } from '@app/model/store'

import { RestItemMenu } from '@pages/RestItem/ui/RestItemMenu'

import { SelectionCardCompilation, SelectionCardCompilationSkeleton } from '@widgets/Selection/SelectionCardCompilation'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { RestaurantSpecialOffer } from '@widgets/Restaurant/RestaurantSpecialOffer'
import { RestaurantReviewsSkeleton } from '@widgets/Restaurant/RestaurantReviews'
import { RestaurantDescription } from '@widgets/Restaurant/RestaurantDescription'
import { RestaurantExtraInfo } from '@widgets/Restaurant/RestaurantExtraInfo'
import { RestaurantNewDishes } from '@widgets/Restaurant/RestaurantNewDishes'
import { RestaurantNews } from '@widgets/Restaurant/RestaurantNews'
import { RestaurantCover } from '@widgets/Restaurant/RestaurantCover'
import { QuestionsAnswers } from '@widgets/Common/QuestionsAnswers'
import { FloatingMenu } from '@widgets/Restaurant/FloatingMenu'

import { PromoRoll } from '@features/PromoRoll'

import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { EGG } from '@shared/api/analytics'
import { GallerySkeleton } from '@shared/ui/Layout/Gallery'
import { RestConcerts } from '@widgets/Restaurant/RestConcerts'
import { DailyCarousel } from '@widgets/Common/Daily/DailyCarousel'
import {QuickBookingRest} from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRest/'

const LazyGallery = dynamic(
  () => import('@widgets/Restaurant/RestaurantGallery').then((module) => module.RestaurantGallery),
  {
    loading: () => <GallerySkeleton />,
    ssr: false,
  },
)

const LazyReviews = dynamic(
  () => import('@widgets/Restaurant/RestaurantReviews').then((module) => module.RestaurantReviews),
  {
    loading: () => <RestaurantReviewsSkeleton />,
    ssr: false,
  },
)

interface Props {
  token2gis: string
}

const RestItem: FC<Props> = ({ token2gis }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const { isDesktopLarge, isDesktop, isMobile, isTablet } = useClientResponsive()
  const refEndRestCover = useRef(null)
  const { similarRests, nearestRests, popularRests, selectionList } = stateRestaurant
  const restaurantData = stateRestaurant.data

  const isRestOpened =
    restaurantData?.status.open || (!restaurantData?.status.open && restaurantData?.status.temporarily)
  const hasAnyBooking =
    restaurantData?.status.open && (restaurantData?.booking?.afisha?.active || restaurantData?.booking?.tomesto?.active)

  const isShowSimilarRests = similarRests.loading || (!similarRests.loading && !!selectionList.list?.length)
  const isShowNearestRests = nearestRests.loading || (!nearestRests.loading && !!nearestRests.list?.length)
  const isShowPopularRests = popularRests.loading || (!popularRests.loading && !!popularRests.list?.length)
  const isShowSelectionList = selectionList.loading || (!selectionList.loading && !!selectionList.list?.length)
  const isShowReviews = !restaurantData || (restaurantData && isRestOpened)
  const isShowQA = !restaurantData || (restaurantData && !!restaurantData.questionsAnswers)

  useEffect(() => {
    EGG.pages.RestItem.appear_screen_page()
  }, [restaurantData?.id])

  return (
    <ErrorBoundary>
      {hasAnyBooking && <FloatingMenu trackedRef={refEndRestCover} />}

      <RestaurantCover />
      <div ref={refEndRestCover}></div>

      <PromoRoll className={s['promo-roll']} />

      {restaurantData?.id && <RestConcerts restId={restaurantData.id} />}

      <ContainerTwoColumns>
        <ContainerTwoColumns.Main className={s['rest-page-column-basic']}>
          {/* basic rest-data column */}
          <RestaurantDescription />

          <LazyGallery thisRestId={restaurantData?.id} />

          {hasAnyBooking && <QuickBookingRest />}

          <RestaurantExtraInfo token2gis={token2gis} />

          {restaurantData?.offers.special && (
            <RestaurantSpecialOffer
              title={restaurantData.offers.special.title}
              description={restaurantData.offers.special.text}
              img={restaurantData.offers.special.photo}
              type_and_date={restaurantData.offers.special.type_verbose}
            />
          )}

          {restaurantData?.offers.news && (
            <RestaurantSpecialOffer
              title={restaurantData.offers.news.title}
              description={restaurantData.offers.news.text}
              img={restaurantData.offers.news.photo}
              type_and_date={restaurantData.offers.news.type_verbose}
            />
          )}

          {restaurantData?.offers.dishes && <RestaurantNewDishes data={restaurantData.offers.dishes} />}

          {restaurantData?.menu && <RestItemMenu data={restaurantData.menu} />}

          {(isMobile || isTablet) && <AdvertTemplate type={'Listing1'} />}

          {isShowSimilarRests && (
            <SectionCarousel countSlidesOnContainer={3} title={'Похожие заведения'} skeleton={similarRests.loading}>
              <SectionCarousel.Items>
                {similarRests.list?.map((item, index) => (
                  <RestCard
                    key={item.id}
                    data={item}
                    sizes={'L ML'}
                    type={'list'}
                    analytic={{
                      advertCardPlaceType: 'similar',
                      section_name: `Похожие заведения`,
                      section_index: index,
                    }}
                  />
                ))}
              </SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => (
                  <RestCardSkeleton key={idx} sizes={'L ML'} />
                ))}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          )}

          {isShowNearestRests && (
            <SectionCarousel countSlidesOnContainer={3} title={'Места рядом'} skeleton={nearestRests.loading}>
              <SectionCarousel.Items>
                {nearestRests.list?.map((item, index) => (
                  <RestCard
                    key={item.id}
                    data={item}
                    sizes={'L ML'}
                    type={'list'}
                    analytic={{
                      advertCardPlaceType: 'nearest',
                      section_name: `Места рядом`,
                      section_index: index,
                    }}
                  />
                ))}
              </SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => (
                  <RestCardSkeleton key={idx} sizes={'L ML'} />
                ))}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          )}

          <RestaurantNews restId={restaurantData?.id} />

          {isShowSelectionList && (
            <SectionCarousel
              countSlidesOnContainer={2}
              title={'Участвует в подборках'}
              skeleton={selectionList.loading}>
              <SectionCarousel.Items>
                {selectionList.list?.map((item, index) => {
                  let getSizeDesktop = 'D1x1'
                  if (isDesktopLarge ? index % 3 === 0 : index % 2 === 0) getSizeDesktop = 'D2x1'

                  return (
                    <SelectionCardCompilation
                      key={item.id}
                      sizes={`${getSizeDesktop} M1x1`}
                      data={item}
                      analytic={{
                        section_name: `Участие в подборках`,
                        section_index: index,
                      }}
                    />
                  )
                })}
              </SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => {
                  let getSizeDesktop = 'D1x1'
                  if (isDesktopLarge ? idx % 3 === 0 : idx % 2 === 0) getSizeDesktop = 'D2x1'

                  return <SelectionCardCompilationSkeleton key={idx} sizes={`${getSizeDesktop} M1x1`} />
                })}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          )}

          {isShowPopularRests && (
            <SectionCarousel
              countSlidesOnContainer={3}
              title={'Популярные рестораны'}
              borderRadius={'16px 16px 0 0'}
              skeleton={popularRests.loading}>
              <SectionCarousel.Items>
                {popularRests.list?.map((item, index) => (
                  <RestCard
                    key={item.id}
                    data={item}
                    sizes={'L ML'}
                    type={'list'}
                    analytic={{
                      advertCardPlaceType: null,
                      section_name: `Популярные рестораны`,
                      section_index: index,
                    }}
                  />
                ))}
              </SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => (
                  <RestCardSkeleton key={idx} sizes={'L ML'} />
                ))}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          )}

          {isShowReviews && <LazyReviews restaurant={{ name: restaurantData?.name, id: restaurantData?.id }} />}

          <AdvertTemplate type={'100%x240 Footer'} />

          {isShowQA && <QuestionsAnswers data={restaurantData?.questionsAnswers} skeleton={!restaurantData} />}

          {!isMobile && !isTablet && <AdvertTemplate type={'Context_100%x70'} />}

          <DailyCarousel />
        </ContainerTwoColumns.Main>

        {!isMobile && !isTablet && (
          <ContainerTwoColumns.Aside>
            <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
            <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />
          </ContainerTwoColumns.Aside>
        )}
      </ContainerTwoColumns>
    </ErrorBoundary>
  )
}

export default RestItem
