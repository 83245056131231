/**
 * @fsd_page OwnerLanding - лендинг ресторатора "Вы владелец"
 */

import React, { useMemo, useState } from 'react'
import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'
import s from './OwnerLanding.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import ImgMainImage from '@images/restorator/main-image.webp'
import ImgAdventure1 from '@images/restorator/advantages-1.webp'
import ImgAdventure2 from '@images/restorator/advantages-2.webp'

import IconRest from '@icons/icon-afisha-restaurants-logo.svg'

import ImgSlider1 from '@images/restorator/slider-1.webp'
import ImgSlider2 from '@images/restorator/slider-2.webp'
import ImgSlider3 from '@images/restorator/slider-3.webp'

import IconSlider1 from '@icons/restorator--logo-slider-1.svg'
import IconSlider2 from '@icons/restorator--logo-slider-2.svg'
import IconSlider3 from '@icons/restorator--logo-slider-3.svg'
import IconArrow from '@icons/icon-arrow-cover.svg'

import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import { Number } from '@shared/ui/Typography/Number'
import Image from 'next/image'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { Header } from '@pages/OwnerLanding/ui/Header'
import { ErrorBoundary } from '../../6_shared/lib/components/ErrorBoundary'
import { ModalAddEstablishment } from '@widgets/Common/ModalAddEstablishment'

const improvements = [
  'Рекомендуем сделать профессиональную фотосъемку и разместить на странице качественные фотографии интерьера и блюд.',
  'Уделите внимание описанию проекта. Чем информативнее текст, тем больше просмотров карточки.',
  'Создавайте информационные поводы. Расскажите об акциях и специальных предложениях.',
  'Отвечайте на отзывы гостей и пользователей. Обратная связь от заведения — отличный пиар.',
]

const sliderData = [
  {
    title: 'Подключение сервиса бронирований',
    description: 'Кнопка бронирования на карточке ресторана и кабинет ресторатора для ведения отчетности',
    img: ImgSlider1.src,
    icon: IconSlider2,
  },
  {
    title: 'Актуальная информация о заведении',
    description: 'Размещение контактов, графика работы, меню и фотографий заведения, текстов в отредактированном виде',
    img: ImgSlider2.src,
    icon: IconSlider1,
  },
  {
    title: 'Размещение новостей',
    description: 'Публикуйте объявления и спецпредложения от имени ресторана',
    img: ImgSlider3.src,
    icon: IconSlider3,
  },
]

const OwnerLanding = () => {
  const { isMobile } = useClientResponsive()

  const [openedForm, setOpenedForm] = useState(false)

  const optionsSlider = useMemo(() => {
    return isMobile
      ? {
          slides: {
            origin: 'center',
            perView: 'auto',
            spacing: 32,
          },
          loop: true,
          defaultAnimation: {
            duration: 1000,
          },
          dragSpeed: 0.7,
        }
      : {
          loop: true,
          defaultAnimation: {
            duration: 1000,
          },
          dragSpeed: 0.7,
        }
  }, [isMobile])

  const handleOpenForm = () => {
    setOpenedForm(true)
  }

  return (
    <ErrorBoundary>
      {openedForm && (
        <ModalAddEstablishment
          handleSendStatus={(value) => console.log(value)}
          handleClose={() => setOpenedForm(false)}
          extended
        />
      )}

      <Header />

      <main className={s.main}>
        <section className={s.header}>
          <div className={s['header-content']}>
            {isMobile ? (
              <Title sizes={'h2'} tag={'h1'} className={s.title}>
                Полная посадка вместе с Афишей
              </Title>
            ) : (
              <Number sizes={'N3'} tag={'h1'} className={s['header-title']}>
                Полная посадка вместе с Афишей
              </Number>
            )}

            <Text sizes={'ML M'} paragraph className={s.subtitle}>
              Подключите свое заведение к Афише Рестораны
              <br /> и прокачайте свою страницу
            </Text>

            <Button sizes={'XL'} mode={'black'} className={s['header-button']} onClick={handleOpenForm}>
              Оставить заявку
            </Button>
          </div>

          <div className={s['header-media']}>
            <Image
              className={s['header-image']}
              src={ImgMainImage.src}
              alt='Фото продуктов Афиша Рестораны'
              width={isMobile ? '392' : '782'}
              height={isMobile ? '253' : '504'}
              quality={100}
            />
          </div>
        </section>

        <ContainerLongCenter>
          <section className={s.opportunities}>
            <Number sizes={'N4'} tag={'h2'} className={s.title}>
              Наши возможности
            </Number>

            <Text sizes={'ML M'} paragraph={!isMobile} className={s.subtitle}>
              Используйте личный кабинет ресторатора и ресурсы Афиши по максимуму
            </Text>

            <div className={s['opportunities-grid']}>
              <div className={s['opportunities-item']}>
                <Title tag={'h3'} sizes={'h3 h5'}>
                  Аналитика, тренды и обзоры
                </Title>
                <Text sizes={'ML M'} className={s['opportunities-item-description']}>
                  Все новости ресторанной индустрии
                </Text>
              </div>

              <div className={s['opportunities-item']}>
                <Title tag={'h3'} sizes={'h3 h5'}>
                  Презентация заведения
                </Title>
                <Text sizes={'ML M'} className={s['opportunities-item-description']}>
                  Создайте страницу-карточку на сайте и в мобильном приложении
                </Text>
                <Image
                  className={s['opportunities-image']}
                  src={ImgAdventure1.src}
                  alt='Мобильное приложение'
                  width={isMobile ? '210' : '262'}
                  height={isMobile ? '432' : '538'}
                  quality={100}
                />
              </div>

              <div className={s['opportunities-item']}>
                <Title tag={'h3'} sizes={'h3 h5'}>
                  Включение в тематические подборки
                </Title>
                <Image
                  className={s['opportunities-image']}
                  src={ImgAdventure2.src}
                  alt='Включение в тематические подборки'
                  width='320'
                  height='240'
                  quality={100}
                />
              </div>

              <div className={s['opportunities-item']}>
                <Title tag={'h3'} sizes={'h3 h5'}>
                  Решения для рекламы
                </Title>
                <Text sizes={'ML M'} className={s['opportunities-item-description']}>
                  Продвижение и платные услуги без посредников
                </Text>
              </div>

              <div className={s['opportunities-item']}>
                <Title tag={'h3'} sizes={'h3 h5'}>
                  Работа с отзывами посетителей
                </Title>
                <Text sizes={'ML M'} className={s['opportunities-item-description']}>
                  Прямая коммуникация с клиентами и пользователями сайта
                </Text>
              </div>

              <Button sizes={'XL'} mode={'black'} className={s['opportunities-button']} onClick={handleOpenForm}>
                Оставить заявку
              </Button>
            </div>
          </section>
        </ContainerLongCenter>

        <ContainerLongCenter>
          <section className={s['slider-section']}>
            <Number sizes={'N4'} tag={'h2'} className={s.title}>
              Вы получаете
            </Number>

            <Text sizes={'ML M'} paragraph={!isMobile} className={s.subtitle}>
              Дополнительные возможности Афиши Рестораны
            </Text>

            <div className={s['slider-wrapper']}>
              <Carousel>
                <div className={s['slider-controls']}>
                  <Carousel.Arrow direction={'prev'} className={s['slider-arrow--prev']}>
                    <IconArrow />
                  </Carousel.Arrow>

                  <Carousel.Arrow direction={'next'} className={s['slider-arrow--next']}>
                    <IconArrow />
                  </Carousel.Arrow>
                </div>

                {/*@ts-ignore*/}
                <Carousel.Slider className={s.slider} spacingMobile={16} optionsKeenSlider={optionsSlider}>
                  {[...sliderData, ...sliderData].map((data) => {
                    const Icon = data.icon
                    return (
                      <div className={s.slide}>
                        <div className={s['slide-content']}>
                          <Title sizes={'h2 h4'} tag={'h3'} className={s['slide-title']}>
                            {data.title}
                          </Title>
                          <div>
                            <Text sizes={'L S'} className={s['slide-description']}>
                              {data.description}
                            </Text>
                            {isMobile && <Icon />}
                          </div>
                        </div>
                        <div className={s['slide-image']}>
                          <Image src={data.img} alt={data.title} fill={true} quality={100} />
                        </div>
                      </div>
                    )
                  })}
                </Carousel.Slider>
              </Carousel>
            </div>
          </section>
        </ContainerLongCenter>

        <ContainerLongCenter>
          <section className={s.improvement}>
            <Number sizes={'N4'} tag={'h2'} className={s.title}>
              Как сделать страницу лучше
            </Number>

            <Text sizes={'ML M'} paragraph={!isMobile} className={s.subtitle}>
              Прокачайте уже созданную страницу заведения для максимальной конверсии
            </Text>

            <ul className={s['improvement-list']}>
              {improvements.map((text, index) => (
                <li className={s['improvement-item']}>
                  {isMobile ? (
                    <Title sizes={'h2'} tag={'h5'} className={s['improvement-item-index']}>
                      {index + 1}
                    </Title>
                  ) : (
                    <Number sizes={'N4'} tag={'span'} className={s['improvement-item-index']}>
                      {index + 1}
                    </Number>
                  )}

                  <Text sizes={'ML S'} paragraph className={s['improvement-item-description']}>
                    {text}
                  </Text>
                </li>
              ))}
            </ul>
          </section>
        </ContainerLongCenter>

        <ContainerLongCenter classNameContainer={s['footer-wrapper']}>
          <section className={s.footer}>
            <Number sizes={'N4'} tag={'h2'} className={s.title}>
              Все заботы берем на себя
            </Number>

            <IconRest className={s['footer-icon']} />

            <Text sizes={'ML M'} paragraph={!isMobile} className={s.subtitle}>
              Будем рады ответить на любые вопросы. <br /> Проконсультируем по вопросам подключения к сервису и рекламе.
            </Text>

            <a href={'mailto:restaurants@afisha.ru'} className={s['footer-mail']}>
              <Text sizes={'ML'} medium>
                restaurants@afisha.ru
              </Text>
            </a>

            <Button sizes={'XL'} mode={'black'} className={s['footer-button']} onClick={handleOpenForm}>
              Оставить заявку
            </Button>
          </section>
        </ContainerLongCenter>
      </main>
    </ErrorBoundary>
  )
}

export default OwnerLanding
