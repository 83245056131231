import React, { FC } from 'react'
import s from './MapButtonClose.module.sass'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconCloseDesktop from '@icons/map/close-desktop.svg'
import IconCloseMobile from '@icons/map/close-mobile.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  onClick: () => void
  disabled?: boolean
  className?: string
}

const MapButtonClose: FC<Props> = ({ onClick, disabled = false, className = '' }) => {
  const { isMobile } = useClientResponsive()

  const handleClickInner = () => {
    onClick()
  }

  return (
    <ErrorBoundary>
      <IconButton
        id={s.button}
        mode={'white'}
        sizes={'XL M'}
        icon={isMobile ? IconCloseMobile : IconCloseDesktop}
        disabled={disabled}
        className={className}
        onClick={handleClickInner}
      />
    </ErrorBoundary>
  )
}

export default MapButtonClose
