import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useEffect } from 'react'
import Authorization from '@app/service/authorization'
import { initAuthAC } from '@app/model/createActions'
import { initCityAC } from '@app/model/actionCreatorCity'
import { TypeGlobalOutput } from '@shared/api/mappingGlobalProps'
import { setCurrentPage } from '@app/model/reduce'
import { initPlugState } from '@widgets/Common/PlugAds/model/actionCreator'
import { resetState } from '@widgets/Common/PlugAds/model/slice'

interface Props {
  data?: TypeGlobalOutput
  page: string
}

// Обработчик глобальных данных
export const useGlobalData = ({ data, page }: Props) => {
  const globalState = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()

  // Получение и запись в стор данных о пользователе (если он авторизирован), если ранее данных не было
  useEffect(() => {
    if (!Authorization.isInit) dispatch(initAuthAC())
  }, [globalState.user, globalState.cityList])

  // Установка данных по выбранному городу
  useEffect(() => {
    dispatch(initCityAC(data))
  }, [data, page])

  useEffect(() => {
    dispatch(setCurrentPage(page))
  }, [page])

  useEffect(() => {
    dispatch(initPlugState())
    return () => {
      dispatch(resetState)
    }
  }, [globalState.currentPage, globalState.cityRead?.id])
}
