import s from './PageLayout.module.sass'

import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'

import { HeaderAdvert } from '@app/PageLayout/components/HeaderAdvert'
import { Mindbox } from '@app/PageLayout/components/Mindbox'
import { RcmScript } from '@app/PageLayout/components/RcmScript'
import { useAppSelector } from '@app/model/store'
import { useErrorCatcher } from '@app/PageLayout/handlers/useErrorCatcher'
import { useGlobalAnalytic } from '@app/PageLayout/handlers/useGlobalAnalytic'
import { useGlobalData } from '@app/PageLayout/handlers/useGlobalData'
import { useResizeWindow } from '@app/PageLayout/handlers/useResizeWindow'
import { useRouterHandler } from '@app/PageLayout/handlers/useRouterHandler'

import { ModalFoundMistake } from '@widgets/Common/ModalFoundMistake'
import { Notices } from '@widgets/Common/Notices'
import { Footer } from '@widgets/Common/Footer'
import { Header } from '@widgets/Common/Header'

import { ContainerGlobal } from '@shared/ui/Layout/Containers/ContainerGlobal'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { TypeGlobalOutput } from '@shared/api/mappingGlobalProps'
import { useQueryABTests } from '@app/PageLayout/handlers/useQueryABTests'
import { Map } from '@widgets/Restaurant/Map'

interface PageLayoutProps {
  children?: JSX.Element
  isLoadingPageProps?: boolean
  globalData?: TypeGlobalOutput
  currentPage: string
}

const LazyBooking = dynamic(() => import('@widgets/Restaurant/Booking/').then((module) => module.Booking), {
  loading: () => <></>,
  ssr: false,
})

const IS_PROD = typeof window !== 'undefined' && window.document.location.host === 'www.afisha.ru'

const PageLayout = ({ children, globalData = null, currentPage, isLoadingPageProps }: PageLayoutProps) => {
  const globalState = useAppSelector((state) => state.global)
  const [isLoadingPage] = useRouterHandler()
  const showLoading = isLoadingPageProps || isLoadingPage
  const router = useRouter()

  useResizeWindow()

  useGlobalAnalytic()

  useGlobalData({ data: globalData, page: currentPage })

  useErrorCatcher()

  useQueryABTests()

  const isOwnerPage = currentPage == 'owner_marketing' || currentPage == 'owner_booking'

  const isShowHeader = router.pathname !== '/[city_slug]/restaurants/restorator' && !router.query.map
  const isServiceMasterPages = currentPage === 'not_found' || currentPage === 'service_err'
  const isShowFooter = !isOwnerPage && !isServiceMasterPages

  const contentBodyClassName = isOwnerPage ? '' : s['body-white']

  const responsiveLoaded = globalState.client.responsive.designEnv !== 'default'
  const isShowFilterMap = globalState.cityRead?.coordinates && globalState.storeLoaded

  return (
    <ErrorBoundary>
      {/* Scripts Layer */}
      <>
        <Mindbox />

        <RcmScript currentPage={currentPage} />
      </>

      {/* Modals Layer */}
      <>
        <LazyBooking />

        <ModalFoundMistake />

        <Notices />
      </>

      {/* Page Layer */}
      <div className={s['body']}>
        {/* Banner Data */}

        {/* Content Data */}
        <div className={contentBodyClassName}>
          <ContainerGlobal className={s.container} data-is-owner={isOwnerPage}>
            {responsiveLoaded && (
              <>
                {/* header block */}
                {isShowHeader && <Header />}

                {isShowFilterMap && <Map />}

                {/* main data block */}
                {globalState.storeLoaded && (
                  <div className={s['wrapper']} data-is-loading={isLoadingPage}>
                    {children}
                  </div>
                )}

                {/* footer block */}
                {globalState.storeLoaded && isShowFooter && <Footer />}
              </>
            )}
          </ContainerGlobal>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default PageLayout
