import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { globalStore } from '@app/model/store'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'

export const fetchAdvSelection = createAsyncThunk(
  'plug-ads/fetchAdvSelection',
  async (offset: number): Promise<TypeMappingSelectionOutput[]> => {
    const storeGlobal = globalStore.getState().global

    return API.selection_list({ params: { page: 1, page_size: 25, city_id: storeGlobal.cityRead.id } }).then((res) => {
      return res.results.filter(
        (data, idx) => !data.categories.selection && !data.categories.advert.active && idx >= offset,
      )
    })
  },
)
