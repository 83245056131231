import React, { FC } from 'react'

import s from './CompilationGrid.module.sass'

import { MediaCardCompilationSkeleton } from '@shared/ui/Layout/Cards/MediaCardCompilation'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useCurrentSize } from '@shared/lib/helpers/classes'

interface Props {
  counts: string // example - '6 4 4' (desktop-large, desktop-medium, tablet)
  className?: string
}

// Component for all breakpoints except for mobile
const Skeleton: FC<Props> = ({ counts = '6 4 4', className = '' }) => {
  const [countsCurrentBreakpoint] = useCurrentSize(counts + ' -')
  const { isMobile } = useClientResponsive()
  const list = Array.from(Array(6).keys())
  const getSizeCard = (index, countsNumber, listLength) => {
    let schema = null

    switch (countsNumber) {
      case '6':
        if (listLength <= 2) schema = ['D2x1', 'D2x1']
        else if (listLength === 3) schema = ['D1x1', 'D1x1', 'D2x1']
        else if (listLength === 4) schema = ['D1x1', 'D1x1', 'D1x1', 'D1x1']
        else if (listLength === 5) schema = ['D1x1', 'D1x1', 'D2x1', 'D2x1', 'D2x1']
        else schema = ['D1x1', 'D1x1', 'D2x1', 'D2x1', 'D1x1', 'D1x1']
        break
      case '4':
        if (listLength === 1) schema = ['D2x1']
        else if (listLength === 2) schema = ['D1x1', 'D2x1']
        else if (listLength === 3) schema = ['D1x1', 'D1x1', 'D1x1']
        else schema = ['D1x1', 'D2x1', 'D2x1', 'D1x1']
        break
    }

    return schema[index]
  }

  return (
    !isMobile && (
      <ErrorBoundary>
        <div className={`${s.grid} ${className}`} data-mode={countsCurrentBreakpoint} data-elems-length={list.length}>
          {list.map((item, index) => {
            const indexLessThanMaximum = index <= Number(countsCurrentBreakpoint) - 1

            if (indexLessThanMaximum) {
              const size = getSizeCard(index, String(countsCurrentBreakpoint), list.length)
              const isLongSize = size === 'D2x1'
              return (
                <MediaCardCompilationSkeleton
                  key={index}
                  // @ts-ignore
                  style={isLongSize ? { gridColumn: 'span 2', minWidth: 'initial' } : {}}
                  sizes={size}
                  className={s.item}
                />
              )
            }
          })}
        </div>
      </ErrorBoundary>
    )
  )
}

export default Skeleton
