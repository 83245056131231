import { combineReducers } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'

import globalReduce from '@app/model/reduce'
import { noticesReducer } from '@widgets/Common/Notices'
import { promoReducer } from '@entities/PromoCard'
import restaurantBooking from '@widgets/Restaurant/Booking/model/reduce'
import { RestItemReducer } from '@pages/RestItem'
import { NewsGalleryReducer } from '@pages/NewsGallery'
import { SelectionsGalleryReducer } from '@pages/SelectionsGallery'
import { NewsItemReducer } from '@pages/NewsItem'
import { SelectionItemReducer } from '@pages/SelectionItem'
import { ListingReducer } from '@pages/Listing'
import { OwnerPersonalPageReducer } from '@pages/OwnerPersonalPage'
import { FoundMistakeReducer } from '@widgets/Common/ModalFoundMistake/'
import { ReviewsReducer } from '@entities/Restaurant/Reviews'
import { FiltersReducer } from '@features/Restaurant/RestaurantFilters'
import { AppHomeReducer } from '@pages/AppHome'
import { MapReducer } from '@widgets/Restaurant/Map'
import { PlugAdsReducer } from '@widgets/Common/PlugAds'

const pagesReducers = combineReducers({
  app_home: AppHomeReducer,
  rest_item: RestItemReducer,
  news_gallery: NewsGalleryReducer,
  selections_gallery: SelectionsGalleryReducer,
  news_item: NewsItemReducer,
  selection_item: SelectionItemReducer,
  listing: ListingReducer,
  owner_personal: OwnerPersonalPageReducer,
  // rest_reviews: RestReviewsReducer,
  // all_rests_listing: AllRestsListingReducer,
  // search_rests_listing: SearchRestsListingReducer,
  // owner_landing: OwnerLandingReducer,
})

const featuresReducers = combineReducers({
  restaurant_filters: FiltersReducer,
  map: MapReducer,
  plugAds: PlugAdsReducer,
})
const entitiesReducers = combineReducers({
  restaurant_reviews: ReviewsReducer,
})

export const rootReducer = combineReducers({
  global: globalReduce,
  notices: noticesReducer,
  booking: restaurantBooking,
  found_mistake: FoundMistakeReducer,
  promo: promoReducer,
  pages: pagesReducers,
  entities: entitiesReducers,
  features: featuresReducers,
})

// Для типизации
const globalStoreForTypes = configureStore({ reducer: rootReducer })

export let globalStore: typeof globalStoreForTypes = null

export const createGlobalStore = (initialStore) => {
  const options: ConfigureStoreOptions = {
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  }

  if (initialStore) options.preloadedState = initialStore
  globalStore = configureStore(options)

  return globalStore
}

const initMakeStore = () => createGlobalStore(null)

export const wrapper = createWrapper(initMakeStore)

export type RootState = ReturnType<typeof globalStore.getState>
export type AppDispatch = typeof globalStore.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
