import React, { FC } from 'react'
import s from './MapRestCarouselMobileItem.module.sass'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import ContentLoader from 'react-content-loader'

const Skeleton: FC = () => {
  return (
    <div className={`${s.wrapper} ${s.skeleton}`}>
      <div className={s.card}>
        <div className={s.main}>
          <div className={s.content}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width='100px'
              height='23px'>
              <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
            </ContentLoader>

            <div className={s['rest-additional-info-wrap']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='149px'
                height='16px'>
                <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
              </ContentLoader>
            </div>

            <div className={s.address}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='90px'
                height='16px'>
                <rect x='0' y='0' rx='9' ry='9' width='100%' height='100%' />
              </ContentLoader>
            </div>

            <div className={s['main-footer']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='28px'
                height='28px'>
                <rect x='0' y='0' rx='12' ry='12' width='100%' height='100%' />
              </ContentLoader>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='42px'
                height='28px'>
                <rect x='0' y='0' rx='12' ry='12' width='100%' height='100%' />
              </ContentLoader>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='28px'
                height='28px'>
                <rect x='0' y='0' rx='12' ry='12' width='100%' height='100%' />
              </ContentLoader>
            </div>
          </div>
          <div className={s['image-wrapper']}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width='68px'
              height='68px'>
              <rect x='0' y='0' rx='10' ry='10' width='100%' height='100%' />
            </ContentLoader>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skeleton
