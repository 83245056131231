import React, { FC, useContext, useEffect, useMemo } from 'react'
import s from './Map.module.sass'
import { MapButtonClose } from '@widgets/Restaurant/Map/ui/MapButtonClose'
import { MapScaleControls } from '@widgets/Restaurant/Map/ui/MapScaleControls'
import { useRouter } from 'next/router'
import { Header } from '@widgets/Common/Header'
import { useBlockScrollOverlay } from '@shared/lib/hooks/useBlockScrollOverlay'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MapRestGrid } from '@widgets/Restaurant/Map/ui/MapRestGrid'
import { closeMapAC, updateMapCenter } from '@widgets/Restaurant/Map/model/actionCreator'
import { MapRestCard } from '@widgets/Restaurant/Map/ui/MapRestCard'
import { load } from '@2gis/mapgl'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { useHandleMarkers } from '@widgets/Restaurant/Map/hooks/useHandleMarkers'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Title } from '@shared/ui/Typography/Title'
import { MapRestCarouselMobile } from './ui/MapRestCarouselMobile'
import { useMapTitle } from '@widgets/Restaurant/Map/hooks/useMapTitle'

const MapContent: FC = () => {
  const cityRead = useAppSelector((store) => store.global.cityRead)
  const { isMobile } = useClientResponsive()
  const { active, selected } = useAppSelector((store) => store.features.map)
  const [mapInstance, setMapInstance] = useContext(MapContext)
  const dispatch = useAppDispatch()
  const router = useRouter()

  useHandleMarkers(mapInstance)

  useBlockScrollOverlay()

  const handleClose = () => {
    dispatch(closeMapAC())
  }

  // Создание карты
  useEffect(() => {
    try {
      if (!active) return
      load().then((mapglAPI) => {
        const map = new mapglAPI.Map('global-map', {
          lang: 'ru',
          controlsLayoutPadding: { top: 20, bottom: 20, left: 16, right: 16 },
          center: cityRead.coordinates ? [cityRead.coordinates[1], cityRead.coordinates[0]] : [37.6156, 55.7522],
          zoom: 13,
          maxZoom: 19,
          minZoom: 7,
          zoomControl: false,
          key: '9c84424f-237a-4c66-bc7e-26bc4f49e606',
          style: '4eda4784-9adb-4c0f-8380-367cad53599c',
          enableTrackResize: true,
          copyright: 'bottomRight',
          trafficControl: false,
        })

        setMapInstance(() => ({ mapglAPI, mapCreated: map }))

        map.on('centerend', (ev) => {
          const { northEast, southWest } = map.getBounds()
          // @ts-ignore
          dispatch(updateMapCenter({ bbox: { northEast, southWest }, center: map.getCenter(), zoom: map.getZoom() }))
        })
        const { northEast, southWest } = map.getBounds()
        // @ts-ignore
        dispatch(updateMapCenter({ bbox: { northEast, southWest }, center: map.getCenter(), zoom: map.getZoom() }))
      })
    } catch (error) {
      console.error('Ошибка инициализации карты', error)
    }
    return () => {
      mapInstance?.mapCreated?.destroy()
      setMapInstance(() => ({ mapglAPI: null, mapCreated: null }))
    }
  }, [active])

  const isShowHeader = !isMobile && router.pathname !== '/[city_slug]/restaurants/restorator'
  const isShowRestItem = selected.data !== null
  const isShowRestGrid = !isShowRestItem

  const mapTitle = useMapTitle()

  return (
    <div className={s.container}>
      {isShowHeader && (
        <div className={s['header-wrapper']}>
          <Header />
        </div>
      )}

      {isMobile ? (
        <div className={s['content-mobile']}>
          <div id='global-map' className={s.map}></div>
          <div className={s.header}>
            <Title className={s.title} sizes={'h4'}>
              {mapTitle}
            </Title>
            <MapButtonClose onClick={handleClose} className={s['button-close']} />
          </div>

          <div className={s.footer}>
            <div className={s['footer-buttons']}>
              {/*<Button sizes={'S'} mode={'white'}>*/}
              {/*  Пример кнопки*/}
              {/*</Button>*/}
            </div>
            <MapRestCarouselMobile />
          </div>
        </div>
      ) : (
        <div className={s['content-desktop']}>
          <div id='global-map' className={s.map}></div>

          <div className={s.aside}>
            {isShowRestGrid && <MapRestGrid />}
            {isShowRestItem && <MapRestCard />}
          </div>

          <div className={s.controls}>
            <MapButtonClose onClick={handleClose} className={s['button-close']} />
            <div className={s['zoom-and-location']}>
              <MapScaleControls />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MapContent
