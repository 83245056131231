import React, { FC } from 'react'
import s from './MapPinLabel.module.sass'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule/schedule'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  restData: TypeMappingRestOutput
}

const MapPinLabel: FC<Props> = ({ restData }) => {
  const { id, name, feedback, price, schedule } = restData

  const getColorRating = () => {
    if (feedback.rating >= 6) return 'green'
    if (feedback.rating < 6 && feedback.rating >= 4) return 'gray'
    else return 'gray'
  }

  const getColorIcon = () => {
    if (feedback.rating >= 6) return 'green'
    else return 'green-light'
  }

  const whenOpens = getShortTextSchedule(schedule.week)

  const isShowRating = Boolean(feedback.rating)
  const isShowPrice = !!restData.price.name?.short
  const isShowSchedule = !!whenOpens

  return (
    <div className={s.container} id={'marker-label'}>
      <div className={s.label}>
        <div className={s.header}>
          <div className={s.icon} data-color={getColorIcon()}></div>
          <span className={s.title} id={'marker-label-title'}>
            {restData.name}
          </span>
        </div>
        <div className={s.footer}>
          {isShowRating && (
            <>
              <span className={s.rating} data-color={getColorRating()}>
                {feedback.rating.toFixed(1)}
              </span>
              {(isShowPrice || isShowSchedule) && <div className={s.dot}></div>}
            </>
          )}

          {isShowPrice && (
            <>
              <span
                id={'marker-label-price'}
                className={`${s['price-range-wrap']} ${s['price-range-wrap--black']} ${s.link}`}>
                {Array(4)
                  .fill(0)
                  .map((el, index) => (
                    <span key={index} data-active={restData.price.level >= index + 1}>
                      ₽
                    </span>
                  ))}
              </span>
              {isShowSchedule && <div className={s.dot}></div>}
            </>
          )}

          {isShowSchedule && <span className={s.schedule}>{whenOpens}</span>}
        </div>
      </div>
    </div>
  )
}

export default MapPinLabel
