import { RestaurantRetrieve } from '@shared/api/types/swaggerTypes'
import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

import {
  generateMenuPDF,
  generateNewDishes,
  genPhoneArray,
  getValueDiscount,
  isDescriptionExist,
  mappingRestMetro,
} from '@shared/api/middleware/mappingAPI/restaurant/helpers/other/other'
import { mappingScheduleWeek } from '@shared/api/middleware/mappingAPI/restaurant/helpers/schedule/schedule'
import { mappingRestStatus } from '@shared/api/middleware/mappingAPI/restaurant/helpers/status/status'
import { isShowDataRest } from '@shared/api/middleware/mappingAPI/restaurant/isShowDataRest'

export const mappingRestaurantItem = (object: RestaurantRetrieve): TypeMappingRestOutput => {
  try {
    const formattedSchedule = object.working_hours_objects ? mappingScheduleWeek(object.working_hours_objects) : null

    const result = undefinedObjectToNull({
      id: object.id,
      name: object.name,
      type: object.restaurant_type,
      status: mappingRestStatus(object),

      menu: generateMenuPDF(object.menu_objects),

      discounts: {
        tastyclub: object.tastyclub_data,
        remarked: getValueDiscount(object.remarked_booking_object?.discount_label),
      },

      deposit: {
        active: object.remarked_booking_object?.has_deposit,
        text: object.remarked_booking_object?.deposit_text || null,
      },

      categories: {
        advert: object.is_advert,
        selection: object.has_selection,
      },

      description: {
        title: isDescriptionExist(object.verdict),
        description: {
          owner: isDescriptionExist(object.owner_info?.description),
          afisha: isDescriptionExist(object.description),
        },
      },

      user: null,

      contacts: {
        phone: {
          // @ts-ignore
          main: object.extra_info.phone,
          // @ts-ignore
          list: object.extra_info.phone_list || genPhoneArray(object.extra_info?.phone),
        },
        // @ts-ignore
        email: object.extra_info.email,
        // @ts-ignore
        site: object.extra_info.site,
      },

      address: {
        city: {
          id: object.city_id,
        },
        address: object.address,
        // @ts-ignore
        metro: mappingRestMetro(object.tags.metro),
        coordinates: [object.longitude, object.latitude],
      },

      images: {
        main: object.main_photo_legacy,
        cover: object.cover_images,
      },

      url: {
        slug: object.url_slug,
        path: object.abs_url,
      },

      booking: {
        // Бронирование через сайт Афиши
        afisha: {
          active: object.has_afisha_booking,
          // nearestSlots: ближайшие слоты, на первые два дня
          remarked: {
            active: !!object.remarked_booking_object?.is_active,
          },
          alert: object.afisha_booking_object
            ? {
                active: !!object.afisha_booking_object?.is_active,
                message: object.afisha_booking_object?.widget_message,
              }
            : null,
        },
        // Бронирование через сервис tomesto
        tomesto: {
          active: object.has_tomesto,
          url: object.tomesto_url,
        },
      },

      offers: {
        special: object.offer_special,
        dishes: generateNewDishes(object.offer_menu),
        news: object.offer_news,
      },

      feedback: {
        rating: object.rating,
        likes: object.likes,
        dislikes: object.dislikes,
        reviews: {
          count: object.reviews_count,
        },
      },

      price: {
        name: {
          // @ts-ignore
          long: object.tags.average_bill?.name,
          // @ts-ignore
          short: object.tags.average_bill?.alt_name,
        },
        // @ts-ignore
        level: object.tags.price_range?.length,
        tag: {
          // @ts-ignore
          id: object.tags.average_bill?.id,
          // @ts-ignore
          url: object.tags.average_bill?.url,
        },
      },

      schedule: {
        // @ts-ignore
        common: object.extra_info.work_time,
        week: formattedSchedule,
        // source: object.working_hours_objects,
      },

      services: {
        // @ts-ignore
        breakfast: Boolean(object.extra_info.breakfast),
        // @ts-ignore
        lunch: Boolean(object.extra_info.business_lunch),
        // @ts-ignore
        delivery: Boolean(object.extra_info.delivery),
        // @ts-ignore
        parking: Boolean(object.extra_info.parking),
        // @ts-ignore
        banquet: Boolean(object.extra_info.has_banquet),
        // @ts-ignore
        catering: Boolean(object.extra_info.catering),
      },

      tags: object.tags,
    })

    result.isShowUI = isShowDataRest(result)

    return result
  } catch (error) {
    console.error(error)
    return null
  }
}
