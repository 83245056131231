import React, { FC } from 'react'
import s from './PlugAdsCard.module.sass'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypePlug } from '@widgets/Common/PlugAds/types/types'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import Image from 'next/image'
import IconLogo from '@icons/plugAdv/icon-rest-logo.svg'
import Link from 'next/link'

interface Props {
  data: TypeMappingSelectionOutput
  plugType: TypePlug
}

const PlugAdsCard: FC<Props> = ({ data, plugType }) => {
  return (
    <ErrorBoundary>
      <Link className={s.card} data-plug-type={plugType} href={data.url.path} target={'_blank'}>
        <div className={s.image}>
          <Image
            src={data.img.sizes.mobile[plugType == 'desktop-large' ? 'vertical' : 'horizontal']}
            alt={data.description.title}
            layout={'fill'}
          />
          <IconLogo className={s.icon} />
        </div>
        <div className={s.content}>
          <Text sizes={'M'} className={s.title}>
            <Text sizes={'M'} medium>
              Подборка:
            </Text>{' '}
            {data.description.title}
          </Text>
          <div className={s['content-footer']}>
            <Text sizes={'S'} className={s.date}>
              {data.date}
            </Text>

            <Button sizes={'M'} mode={'black'}>
              Посмотреть
            </Button>
          </div>
        </div>
      </Link>
    </ErrorBoundary>
  )
}

export default PlugAdsCard
