import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import s from './MapRestCarouselMobile.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import {
  MapRestCarouselMobileItem,
  MapRestCarouselMobileItemSkeleton,
} from '@widgets/Restaurant/Map/ui/MapRestCarouselMobile/ui/MapRestCarouselMobileItem'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actionCreator'
import { MapRestCarouselMobileNotFound } from '@widgets/Restaurant/Map/ui/MapRestCarouselMobile/ui/MapRestCarouselMobileNotFound'

// Анимация Fade-in Fade-out если выбран пин, слайд которого находится далеко
const customSlideToLoop = (swiper, newRealIndex) => {
  // Анимация Fade-in Fade-out если выбран пин, слайд которого находится далеко
  if (Math.abs(swiper.realIndex - newRealIndex) > 1) {
    const currentSlideWrapper = swiper.slides[swiper.activeIndex].querySelector('#rest-card-map-mobile')
    currentSlideWrapper.classList.add('hidden-view')
    currentSlideWrapper.firstChild.style.height = '141px'
    swiper.wrapperEl.style.transitionProperty = 'height'

    setTimeout(() => {
      swiper.slideToLoop(newRealIndex, 400)
      currentSlideWrapper.firstChild.style.height = null
      currentSlideWrapper.classList.remove('hidden-view')
    }, 400)
    setTimeout(() => {
      swiper.wrapperEl.style.transitionProperty = null
    }, 800)

    // Корректировка анимации чтоб слайдер иногда не улетал в соседний слайд
  } else {
    swiper.wrapperEl.style.transitionTimingFunction = 'cubic-bezier(.22,.11,0,1)'
    setTimeout(() => {
      swiper.wrapperEl.style.transitionTimingFunction = null
    }, 850)
    swiper.slideToLoop(newRealIndex)
  }
}

const MapRestCarouselMobile: React.FC = () => {
  const { restaurants, selected } = useAppSelector((store) => store.features.map)
  const dispatch = useAppDispatch()
  const [mapInstance, setMapInstance] = useContext(MapContext)
  const [swiperInit, setSwiperInit] = useState(false)
  const swiperRef = useRef(null)
  const [arrRest, setArrRests] = useState([])
  const [initSlide, setInitSlide] = useState(0)

  const getRestIdCurrentSlide = (swiper) => {
    try {
      return swiper.slides[swiper.activeIndex].dataset.restId
    } catch (e) {
      return 0
    }
  }

  const handleChangeSlide = (swiper) => {
    const restIdNewSlide = getRestIdCurrentSlide(swiper)

    // Случай ниже может быть, если пользователь выбрал ресторан в одном городе -> переместился в другой -> выбранный ресторан из списка удалился, но в выбранных остался
    if (!(restIdNewSlide in restaurants.data)) return

    dispatch(setRestSelectedAC({ restData: restaurants.data[restIdNewSlide], eventFrom: 'slider', mapInstance }))
  }

  useEffect(() => {
    if (!selected.data || !restaurants.data) return

    arrRest.length ? sliderUpdateLength() : sliderInit()
  }, [restaurants.data, !!selected.data])

  const sliderInit = () => {
    const arrayRests = Object.values(restaurants.data)
    setInitSlide(arrayRests.findIndex((el) => el.id === selected.data.id))
    setArrRests(arrayRests)
    setSwiperInit(true)
  }

  const sliderUpdateLength = () => {
    if (!swiperRef.current) return

    // Запоминаем индекс активного слайда перед обновлением слайдера новыми ресторанами
    const idxCurrentSlide = swiperRef.current.realIndex
    const dataCurrentSlide = arrRest[idxCurrentSlide]
    const updateArrayRests = []
    let idxCurrentSlideInUpdateArr = null

    Object.values(restaurants.data).forEach((el, idx) => {
      if (el.id === dataCurrentSlide.id) idxCurrentSlideInUpdateArr = idx
      updateArrayRests.push(el)
    })

    // Если в новом массиве выбранного элемента нет, добавляем выбранный элемент в начало нового массива
    if (idxCurrentSlideInUpdateArr === null) {
      customSlideToLoop(swiperRef.current, 0)
    }
    // Если индексы активного элемента в старом и новом массиве не совпадают, исправляем это, меняя местами элементы
    else if (idxCurrentSlide !== idxCurrentSlideInUpdateArr) {
      const moveFirstElem = JSON.parse(JSON.stringify(updateArrayRests[idxCurrentSlide]))
      const moveSecondElem = JSON.parse(JSON.stringify(updateArrayRests[idxCurrentSlideInUpdateArr]))

      updateArrayRests[idxCurrentSlide] = moveSecondElem
      updateArrayRests[idxCurrentSlideInUpdateArr] = moveFirstElem
    }

    setArrRests(updateArrayRests)
  }

  useEffect(() => {
    if (!swiperRef.current) return
    const selectedIndex = arrRest.findIndex((el) => el.id == selected.data.id)
    if (swiperRef.current.realIndex === selectedIndex) return

    customSlideToLoop(swiperRef.current, selectedIndex)
  }, [selected.data])

  return (
    <ErrorBoundary>
      {swiperInit ? (
        <Swiper
          className={s.slider}
          speed={800}
          slidesPerView={1}
          loop={true}
          initialSlide={initSlide}
          autoHeight={true}
          spaceBetween={8}
          onRealIndexChange={handleChangeSlide}
          onSwiper={(swiper) => (swiperRef.current = swiper)}>
          {arrRest.length ? (
            arrRest.map((rest, index) => (
              <SwiperSlide key={`${index} ${rest.id}`} data-rest-id={rest.id}>
                <MapRestCarouselMobileItem data={rest} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide key={'rests-not-found'} data-rest-id={'rests-not-found'}>
              <MapRestCarouselMobileNotFound />
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <div className={s.skeleton}>
          <MapRestCarouselMobileItemSkeleton />
          <MapRestCarouselMobileItemSkeleton />
          <MapRestCarouselMobileItemSkeleton />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(MapRestCarouselMobile)
