import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

/** @function isShowDataRest
 * На основе данных ресторана возвращает объект с полями,
 * которые указывают отображать в ui тот или иной элемент
 * */
export const isShowDataRest = (data: TypeMappingRestOutput) => {
  const { status, categories, feedback, price: priceData, images, booking: bookingData } = data

  const countReviews = feedback.reviews.count
  const getImg = images.cover.rest1894x1000 || images.main

  const img = [0, 1, 3].includes(status.code) && !!getImg
  const likeButton = [0, 1, 3].includes(status.code)
  const reviewsButton = [0, 1].includes(status.code)
  const actionButtons = likeButton || reviewsButton
  const rating = [0, 1, 2, 10].includes(status.code) && (feedback.rating >= 4 || countReviews > 0)
  const chevron = [0, 1, 2, 10].includes(status.code) && categories.selection
  const price = [0, 1].includes(status.code) && priceData.tag?.id
  const booking = [0].includes(status.code) && (bookingData.afisha.active || bookingData.tomesto.active)

  return {
    img,
    likeButton,
    reviewsButton,
    actionButtons,
    rating,
    chevron,
    price,
    booking,
  }
}
