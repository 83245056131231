import s from './RestaurantSchedule.module.sass'

import React, { FC, memo, useState } from 'react'
import { Text } from '@shared/ui/Typography/Text'
import { Modal } from '@shared/ui/Overlays/Modal'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import IconOpenSchedule from '@icons/icon-arrow-small.svg'
import IconWarning from '@icons/icon-warning.svg'
import { EGG } from '@shared/api/analytics'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule/schedule'
import { PopupHeader } from '@shared/ui/Overlays/Popup/ui/PopupHeader'
import { Popup } from '@shared/ui/Overlays/Popup'
import { PopupClose } from '@shared/ui/Overlays/Popup/ui/PopupClose'
import { TypeMappingRestSchedule } from '@shared/api/middleware/mappingAPI/restaurant/type'


type Props = {
  schedule: TypeMappingRestSchedule
  classNameButton?: string
  classNamePopup?: string
}

const RestaurantSchedule: FC<Props> = ({ schedule, classNameButton = '', classNamePopup = '' }) => {
  const [buttonRef, setButtonRef] = useState()
  const [openedDetailSchedule, setOpenedDetailSchedule] = useState(false)
  const { isMobile } = useClientResponsive()
  const week = schedule.week
  const whenOpens = getShortTextSchedule(week)

  const onOpenChangePopup = (value) => {
    !value && handleClosePopup()
  }

  const handleOpenPopup = () => {
    if (whenOpens && !openedDetailSchedule) {
      EGG.pages.RestItem.schedule_click_open()
      setOpenedDetailSchedule(true)
    }
  }

  const handleClosePopup = () => {
    EGG.pages.RestItem.schedule_click_close()
    setOpenedDetailSchedule(false)
  }

  const WarningTemplate = () => (
    <div className={s.notice}>
      <IconWarning />
      <span className={s['notice-text']}>Фактический график ресторана может отличаться</span>
    </div>
  )

  const ListScheduleTemplate = () => (
    <div className={s['popup-list']}>
      {Object.entries(week).map(([indexDay, item]) => {
        if (indexDay === '0') return
        return (
          <div key={indexDay} className={s['popup-list-item']}>
            <Text tag={'span'} sizes={'M'} paragraph>
              {item.weekday}
            </Text>
            <Text tag={'span'} sizes={'M'} paragraph data-is-open={!!item.open}>
              {item.open ? `${item.open}-${item.close}` : 'не работает'}
            </Text>
          </div>
        )
      })}

      <div className={s['popup-list-item']}>
        <Text tag={'span'} sizes={'M'} paragraph>
          {week[0].weekday}
        </Text>
        <Text tag={'span'} sizes={'M'} paragraph data-is-open={!!week[0].open}>
          {week[0].open ? `${week[0].open}-${week[0].close}` : 'не работает'}
        </Text>
      </div>
    </div>
  )

  return (
    <>
      <button
        className={`${s['rest-schedule']} ${classNameButton}`}
        // @ts-ignore
        ref={setButtonRef}
        onClick={handleOpenPopup}
        data-not-click={openedDetailSchedule}>
        <Text tag='span' sizes='M S' className={s['rest-schedule-text']}>
          {whenOpens || 'Время работы не указано'}
        </Text>

        {whenOpens && <IconOpenSchedule className={s['rest-schedule-icon']} data-opened={openedDetailSchedule} />}
      </button>

      {isMobile ? (
        openedDetailSchedule ? (
          <Modal size={'S'} title={'График работы'} handleClose={handleClosePopup} className={classNamePopup}>
            <ListScheduleTemplate />
            <WarningTemplate />
          </Modal>
        ) : null
      ) : (
        <Popup triggerRef={buttonRef} open={openedDetailSchedule} onOpenChange={onOpenChangePopup}>
          <PopupContent style={{ width: '320px' }} className={`${s.popup} ${classNamePopup}`}>
            <PopupClose />
            <PopupHeader>Время работы</PopupHeader>
            <ListScheduleTemplate />
            <WarningTemplate />
          </PopupContent>
        </Popup>
      )}
    </>
  )
}

export default memo(RestaurantSchedule)
