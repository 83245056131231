import React, { FC } from 'react'
import s from './MapMobile.module.sass'
import { Template } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import { TastyClub } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TastyClub'
import TypeAndPrice from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TypeAndPrice/TypeAndPrice'
import { Place } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/Place'
import { ScheduleAndPrice } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/ScheduleAndPrice'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import Image from 'next/image'
import { Button } from '@shared/ui/Actions/Button'
import { openBooking } from '@widgets/Restaurant/Booking'
import { EGG } from '@shared/api/analytics'
import { useAppDispatch } from '@app/model/store'
import { Text } from '@shared/ui/Typography/Text'
import Link from 'next/link'

const MapMobile: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ data, sizes, analytic, send_analytic }) => {
  const dispatch = useAppDispatch()
  const [size] = useCurrentSize(sizes)

  const handleBooking = (event) => {
    event.stopPropagation()
    event.preventDefault()

    const dataAfisha = data.booking.afisha
    const dataTomesto = data.booking.tomesto

    if (dataAfisha.active) {
      dispatch(openBooking(data))
    } else if (dataTomesto.active) {
      window.open(dataTomesto.url, '_blank')
    }

    if (send_analytic) {
      EGG.entity.restaurant.click_booking(data, {
        section_name: analytic.section_name,
        section_index: analytic.section_index,
      })
    }
  }

  const price = data.price
  const address = data.address.address
  const metro = data.address.metro
  return (
    <ErrorBoundary>
      <div className={s.card}>
        <div className={s.content}>
          <Link href={data.url.path} scroll={false}>
            <Text tag={'span'} sizes={'ML'} medium>
              {data.name}
            </Text>
          </Link>
          <div className={s['block-type-and-place']}>
            <TypeAndPrice
              restType={data.type}
              tag={data?.tags?.main_tag}
              priceRange={size !== 'S' ? price.level : null}
              priceLink={price.tag.url}
            />
            <Place address={address} metro={metro} />
          </div>
        </div>
        <Image src={data.images.main} width={68} height={68} alt={'Фото интерьера ресторана ' + data.name} />
        {(data.booking.afisha.active || data.booking.tomesto.active) && (
          <Button sizes={'S'} mode={'black'} onClick={handleBooking}>
            Забронировать
          </Button>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default MapMobile
