import React, { FC, SVGProps } from 'react'
import s from './MapButtonActionForAside.module.sass'

interface Props {
  icon: FC<SVGProps<SVGSVGElement>>
  className?: string
}

const MapButtonActionForAside: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ icon, className = '', ...props }) => {
  const Icon = icon
  return (
    <button className={`${s.close} ${className}`} {...props}>
      <Icon />
    </button>
  )
}

export default MapButtonActionForAside
